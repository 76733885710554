import React, {useContext, useEffect, useState,useCallback,useRef} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';
import {
  SuggestedAnswer,
  MachinResultsContainer,
  GeneratedResultsContainer,
  LineClamp,
  } from '../styles'; // we use the style sheet from results cards
import { Button, Col, Container,Row } from 'react-bootstrap';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';
import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import Hr from '../../hr/index.js';
import { H2,P,Label,A } from '../../theme/typography.js';
import PublisherTag from '../../publisher-tag';
import ResultCard from '../result-card/index.js';
import AnnotationWrapperCard from '../annotation-wrapper-card/index.js';
import {ReactComponent as Sparkles} from '../../../assets/svgs/sparkles-outline.svg';
import AnnotationsCard from '../annotations-card/index.js';



const GeneratedAnswer = (props) => {
    const {
        fetchedResults,
        fetchedSuggestedQuestion,
        progress,
        machineResults,
        localWebResults,
        isResultsExpanded,
        setIsResultsExpanded,
        fetchResults,
        trackers,
        setActiveChatIndex,
        loadMore,
        setLoadMore,
        setQueueDone,
        clearLocalWebResults,
        setClearLocalWebResults,
        assistedAiAllowWebUsage,
        searchContext,
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [refencesExpanded, setRefencesExpanded] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(true);
  const [searchResultsReady, setSearchResultsReady] = useState(false);
  const [suggestedQuestionReady, setSuggestedQuestionReady] = useState(false);
  const [aiAnswerReady, setAiAnswerReady] = useState(false);
  const [aiQuotesReady, setAiQuotesReady] = useState(false);
  const [streamAnswer, setStreamAnswer] =useState("");
  const [annotations,setAnnotations] =useState(undefined);
  const [listAnnotations,setListAnnotations] =useState(undefined);
  const [elementToScroll, setElementToScroll] =useState(undefined );
  const [keyToScroll, setKeyToScroll] =useState(undefined );

  
  const AnnotationsRef = useRef(null);



  function toggleAIResults() {
    setContainerIsExpanded(!containerIsExpanded);
  }
  function expandReferences(){
    setRefencesExpanded(true);
  };
  function unexpandReferences(){
    setRefencesExpanded(false);
  };


  
  // since this function is store for call back
  const expandAnnotationsAndSetScroll = (id,key)=>{
    console.log('69',id);
    console.log('69type of id ',typeof id);
    var target_element_query = "h2 span."+id ; 
    setElementToScroll(target_element_query);
    setKeyToScroll(key);
    // scrollToIndex(target_element_query);
    // var target_element =  document.querySelectorAll("h2 span."+id);
    // if (target_element && target_element !== null){
    //   target_element =  target_element[0];
    // }
    // console.log('74', target_element);
    // console.log('75', typeof target_element);
    // if(typeof target_element !== "undefined" && target_element !== null ){
    //   console.log('77 refencesExpanded ',refencesExpanded);
    //   if(refencesExpanded){
    //     console.log('84 scroll');
    //     target_element.scrollIntoView();}
    //   else{
    //     console.log('87 on expanded ');
    //     setElementToScroll(target_element_query);// if references are not expanded we can not target them
    //   }
    // }
    // if(typeof target_element === "undefined" || target_element === null ){
    //   console.log('79');
    //   setElementToScroll(target_element_query);// if references are not expanded we can not target them
    // }
    // console.log('expanding',refencesExpanded);
    if(!refencesExpanded){
      console.log('expanding');
      setRefencesExpanded(true);
    }
  };
  
  const addLinksToAnnotation = (annotation_dicts)=> {
    for (const [key, value] of Object.entries(annotation_dicts)) {
      console.log('85');
      console.log(key, value);
      // console.log('key split', key.split('"'));
      let temp_id = key.split('"')[1];
      console.log('90 temp_id', temp_id);
      let temp_elements =  document.querySelectorAll("span."+temp_id);
      console.log('92 temp_elements', temp_elements);
      temp_elements.forEach(element => {
        console.log('94 elem',element);
        console.log('95 elem',key);
        let temmp_str = temp_id.slice();
        console.log('95 elem',temmp_str);
        element.addEventListener("click", ()=>{expandAnnotationsAndSetScroll(temmp_str,key); return false});
      });
    }
  };


  const ExpandButton =  props => {
    return(
      <Button
      size="sm"
      className="progress-button"
      style={{
        cursor: aiAnswerReady ? 'default' : 'pointer',
      }}
      >
      {containerIsExpanded  ?  (
        <FaChevronUp color="#fff"/>
      ) : (
        <FaChevronDown color="#fff" />
      )}
      </Button>
    );
  }

  useEffect(() => {
    if(containerIsExpanded !==  isResultsExpanded && typeof containerIsExpanded !== 'undefined' && typeof isResultsExpanded !== 'undefined'){
      setIsResultsExpanded(containerIsExpanded);
    }
    if(containerIsExpanded){
      console.log('124 containerExpanded...');
      if(annotations && listAnnotations && listAnnotations.length >0  ){
        addLinksToAnnotation(annotations);
      }
    }
  }, [containerIsExpanded,listAnnotations,refencesExpanded]);

  useEffect(() => {
    
    console.log('161 reference expanded',refencesExpanded);
  }, [refencesExpanded])
  
  useEffect(() => {
    console.log('165 reference expanded',elementToScroll);
  
  }, [elementToScroll])
  

  // useEffect(() => {
  //   console.log('157 refencesExpandeduse effect',refencesExpanded);
  //   console.log('158 elementToScroll ',elementToScroll);

  //   if(typeof refencesExpanded !=="undefined" && refencesExpanded && typeof elementToScroll !=="undefined" && elementToScroll !== null ){
  //     console.log('161 refencesExpandeduse effect');
  //     var target_element =  document.querySelectorAll(elementToScroll);
  //     console.log('target_element', target_element);
  //     if(typeof target_element !== 'undefined' && target_element !== null &&  target_element.length>0 ){
  //       console.log('164scroll to',target_element[0]);
  //       target_element[0].scrollIntoView();
  //     }
  //     setElementToScroll(undefined);
  //   }
  // }, [refencesExpanded]);
  

  useEffect(() => {

    if(props.references && props.references.length >0 && props.references[0]){
      setContainerIsExpanded(false);
    }
  }, [searchContext]);
  

  useEffect(() => {
    if(streamAnswer ){
      var pre_tag = document.getElementById('typewriter');
      if(typeof pre_tag!=='undefined' &&  pre_tag !== null && typeof  pre_tag.innerHTML !== 'undefined'&&  pre_tag.innerHTML !== null){
        document.getElementById('typewriter').innerHTML =streamAnswer;}
    }
  }, [streamAnswer]);

  useEffect(() => {
    let temp_annotations  ; 
    if(annotations){
      temp_annotations =  Object.entries(annotations) ; 
      console.log('temp annotations',temp_annotations );
      setListAnnotations(temp_annotations);
      // addLinksToAnnotation(annotations);
    }
  }, [annotations]);

  // useEffect(() => {
  //   if(annotations && listAnnotations && listAnnotations.length >0  ){
  //     addLinksToAnnotation(annotations);
  //   }
  // }, [listAnnotations])
  
  // function scrollToIndex(elementToScroll) {
  //   const listNode = AnnotationsRef.current;
  //   // This line assumes a particular DOM structure:
  //   // const imgNode = listNode.querySelectorAll('')[index];
  //   const annotationCardNode = listNode.querySelectorAll(elementToScroll);
  //   if(annotationCardNode && annotationCardNode.length>0 ){
  //     annotationCardNode[0].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center',
  //       inline: 'center'
  //     });}
  //   else{
  //     console.log('218',annotationCardNode);
  //     console.log('219',listNode.querySelectorAll(elementToScroll));
  //   }
  // }
  

  useEffect(() => {
    let event_source; 
    function answer_event_listener(e_source, event, callback_function, init_result) {
      console.log('176 event received:', event);
      var data = JSON.parse(event.data);
      console.log('178data ', data);
      console.log('178 data ', data.annotations);
      console.log('178 data ',typeof data.annotations);

      if(data && data.result){
        if(!aiAnswerReady){
          setAiAnswerReady(true);}
        setStreamAnswer(data.result);
      }     
      if(data && data.annotations){
        setAnnotations(data.annotations);
        if(!aiQuotesReady){
          setAiQuotesReady(true);
        }
      }
      var res = callback_function(init_result, data);
      if (!res) {
          console.log('closing stream because of callback', event);
          e_source.close();
      } else {
        console.log('140');
          if (data.type === "final") {
              console.log('closing stream because completed', event);
              e_source.close();
          }
      }
    }
  
    function stream_answer_from_query(query, init_for_callback, callback_function) {
      // see answer_event_listener on example of callback_function
      
      event_source = new EventSource(`https://ask.cairo.medwise.ai/answer_stream?q=${query}`);
      // event_source = new EventSource(`http://localhost:8009/answer_stream?q=${query}`);
      // var event_source = new EventSource(`${window.BASE_URL_ASK}/answer_stream?q=${query}`);
  
      var init_result = init_for_callback();
      event_source.addEventListener('message', (event) => {
          answer_event_listener(event_source, event, callback_function, init_result)
      });
    }
  
  // callback_function
    function process_stream(init_arg, data) {
      console.log("145 init_arg :", init_arg);
      console.log("146 received data:", data);
      if (data.type === "error") {
          return false;
      }
      return true;
    }
  
  
    function process_text (init_arg,data){
    console.log('154 data',data);
    console.log('155 init_arg',init_arg);
    // if(typeof data === "undefined"){
    //   console.log('158');
    //   return false;
    // }
    return 'bla';
    // setQuebstion(data);
    };
  
    stream_answer_from_query( searchContext.q, process_text, process_stream);
  
    return () => {
      // Clean up the event source when the component is unmounted
      event_source.close();
    };
  }, []);


  return (
    <GeneratedResultsContainer
        style={{
            paddingBottom: containerIsExpanded && '1px',
            marginBottom:
            containerIsExpanded && !isSmallScreen
                ? '15px'
                : containerIsExpanded && isSmallScreen && '8px',
            display:( !( fetchedResults && fetchedResults.length >0 )) && 'none',
            background: 'linear-gradient(#eae6f8,#fbfafd)',
            // backgroundColor: (question) ? '#fffff':'#fffff',
            // border:"",
            // backgroundo: (question) && '0.16',
        }}
    >
        {/* <Container><Row>
        <div style={{display:'flex', alignItems:'center',justifyContent:'center',width:'100%' }}> 
        <P className="py-0 my-0"
          italic
          textoffblack
          style={{margin:'14px',fontStyle:"italic" }} >Expand this card to see a LLM generated answer, and it may be inaccurate </P>
          
        </div>
        </Row>
        </Container> */}
        {/* Comment/Uncomment */}
        <SuggestedAnswer
          p1
          regular
          textoffblack
          className={aiAnswerReady ? "tittle_":"blink"}
          onClick={toggleAIResults}
          style={{
            cursor: aiAnswerReady ? 'pointer':'default' ,
            // marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px'
          }}
        >
              <>
              {(aiAnswerReady)? (<ExpandButton></ExpandButton>):(
                <div
                style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:'4px 6px 0 6px',
                      }}
                >
                <Loader visible={true} color="#8771DF"  height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
                </div>
              )}
              {' '}
              <Container>
               <Row style={{ display:'flex',justifyContent:'space-between'}}>
                <div 
                  style={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 100px)'}}>
                    <LineClamp className= { "truncate"}
                        > Expand this card to see an LLM generated answer, it may be inaccurate</LineClamp>
                </div>
              </Row>
              </Container>
              </>
          
        </SuggestedAnswer>

        {/* AI ASSISTED RESULTS */}
        {containerIsExpanded && aiAnswerReady &&(
          <>
          <Container>
            <Row>
              <div
              className={`card_body_generated_answer `}
              aria-expanded={containerIsExpanded ? true : false}
              style={{display:"flex", flexDirection: "column", alignItems:'center'}}
              >
              <pre id='typewriter' style={{ whiteSpace:'break-spaces',width: '100%'}}  className="px-2 py-0"  dangerouslySetInnerHTML={{__html:streamAnswer}}/>
            </div>
            </Row>
            <Hr />
            {aiQuotesReady  && listAnnotations && listAnnotations.length > 0 && (
            <Row className="justify-content-center p-1">
              {!refencesExpanded  ?(
              <Label onClick={expandReferences}>
                 <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown> References <FaChevronDown style={{paddingTop:"6px"}}></FaChevronDown>
              </Label>):(
              <Label onClick={unexpandReferences}>
                <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp> References <FaChevronUp style={{paddingTop:"6px"}}></FaChevronUp>
              </Label>)
              }
            </Row>)}
            <div ref={AnnotationsRef}>
            {refencesExpanded && aiQuotesReady && listAnnotations &&  listAnnotations.length > 0 &&
            //  listAnnotations.map((annotation,index) => {
            //   return (
            //   <>
            //  <Row>
            //  <AnnotationWrapperCard
            //       annotation_id = {annotation[0]}
            //       annotation = {annotation[1]}
            //       i ={index}></AnnotationWrapperCard>
              
            // </Row>

            //   {/* <Row className='justify-content-center p-3'>
            //       <Hr/>
            //   </Row> */}

            //   </>
            //   )}
            // )
            <AnnotationsCard
             listAnnotations={listAnnotations}
              elementToScroll={elementToScroll}
               setElementToScroll={setElementToScroll}
               keyToScroll ={keyToScroll}
               setKeyToScroll={setKeyToScroll}></AnnotationsCard>
            }
            </div>

          </Container>
          </>)}
    </GeneratedResultsContainer>
  );
};

export default GeneratedAnswer;
