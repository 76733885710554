import React, {useContext, useEffect, useState, useMemo,useCallback} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';
import {
  SuggestedAnswer,
  MachinResultsContainer,
  GeneratedResultsContainer,
  LineClamp,
  } from '../styles'; // we use the style sheet from results cards
import { Button, Col, Container,Row } from 'react-bootstrap';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';
import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import Hr from '../../hr/index.js';
import { H2,P,Label,A } from '../../theme/typography.js';
import PublisherTag from '../../publisher-tag';
// import ResultCard from '../result-card/index.js';
import UniversalCard from '../universal-card/index.js';
import {ReactComponent as Sparkles} from '../../../assets/svgs/sparkles-outline.svg';
import ExtractiveIcon from '../../../assets/svgs/audit_purple.png';


const ExtractiveAnswer = (props) => {
    const {
        fetchedResults,
        fetchedSuggestedQuestion,
        progress,
        machineResults,
        localWebResults,
        isResultsExpanded,
        setIsResultsExpanded,
        fetchResults,
        trackers,
        setActiveChatIndex,
        loadMore,
        setLoadMore,
        setQueueDone,
        clearLocalWebResults,
        setClearLocalWebResults,
        assistedAiAllowWebUsage,
        numMachineResults,
        searchContext,
        llmAnswerUnavailable,
        setLLmAnswers,
        llmAnswers,
        llmQuestion
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [refencesExpanded, setRefencesExpanded] = useState(false);
  const [searchResultsReady, setSearchResultsReady] = useState(false);
  const [suggestedQuestionReady, setSuggestedQuestionReady] = useState(false);
  const [aiAnswerReady, setAiAnswerReady] = useState(false);
  const [aiQuotesReady, setAiQuotesReady] = useState(false);
  const [answerProgress, setAnswerProgress] =useState(80 );
  const [responseText, setResponseText] =useState("");

  


  function toggleAIResults() {
    if(llmAnswers && llmAnswers.length>0){
      setContainerIsExpanded(!containerIsExpanded);
    }
  }


  const ExpandButton =  props => {
    return(
      <Button
      size="sm"
      className="progress-button"
      style={{
        cursor: answerProgress >= 100 ? 'default' : 'pointer',
      }}
      >
      {containerIsExpanded  ?  (
        <FaChevronUp color="#fff"/>
      ) : (
        <FaChevronDown color="#fff" />
      )}
      </Button>
    );
  }

  useEffect(() => {
    if(containerIsExpanded !==  isResultsExpanded && typeof containerIsExpanded !== 'undefined' && typeof isResultsExpanded !== 'undefined'){
      setIsResultsExpanded(containerIsExpanded);
    }
  }, [containerIsExpanded]);


  useEffect(() => {
    if(llmAnswers && llmQuestion && !llmAnswerUnavailable ){
      setAiAnswerReady(true);
    }
  }, [llmAnswerUnavailable, llmAnswers,llmQuestion]);
  
  useEffect(() => {
    setContainerIsExpanded(false);
    setAiAnswerReady(false);
    setAnswerProgress(0);
  }, [searchContext]);



  return (
    <GeneratedResultsContainer
        style={{
            paddingBottom: containerIsExpanded && '1px',
            marginBottom:
            containerIsExpanded && !isSmallScreen
                ? '15px'
                : containerIsExpanded && isSmallScreen && '8px',
            border:( !aiAnswerReady) ? 'none':'1px solid #E2E5ED',
            display:( !( (fetchedResults && fetchedResults.length >0) || aiAnswerReady)) && 'none',
            background: 'linear-gradient(#eae6f8,#fbfafd)',
            // backgroundColor: (question) ? '#fffff':'#fffff',
            // border:"",
            // backgroundo: (question) && '0.16',
        }}
    >
        {/* Comment/Uncomment */}
        {/* {fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && !isSmallScreen && ( */}
        <SuggestedAnswer
          p1
          regular
          textoffblack
          className={aiAnswerReady ? "tittle_":"blink"}
          onClick={toggleAIResults}
          style={{
            cursor: answerProgress <=100? 'default' : 'pointer',
            // marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px'
          }}
        >
          { true &&(
              <>
              {(aiAnswerReady)? (<ExpandButton></ExpandButton>):(
                <div
                style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:'4px 6px 0 6px',
                      }}
                >
                <Loader visible={true} color="#8771DF"  height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
                </div>
              )}
              {' '}
              <Container>
            {llmQuestion ? (

              <Row style={{ display:'flex',justifyContent:'space-between'}}>
                <div 
                  style={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 100px)'}}>
                    <LineClamp className= {containerIsExpanded? "": " truncate"}
                        dangerouslySetInnerHTML={{ __html:llmQuestion}}></LineClamp>
                </div>
               <div style={{display:'flex', justifyContent:'flex-end',alignItems: 'center'}}>
                  {!isSmallScreen &&(
                  <P p1 regular textoffblack > </P>)}
                  {/* <Sparkles style={{margin:'12px'}} color="#8771DF" height="23px" width="23px"></Sparkles> */}
                  <img src={ExtractiveIcon} style={{margin:'12px'}} color="#8771DF" height="23px" width="23px" alt='extractive AI icon'></img>

                  
                </div>
              </Row>
            ):(            
            <Row style={{ display:'flex',
                      justifyContent:'space-between' 
                    }}>
              <div 
              style={{display:'flex', justifyContent:'flex-start', alignItems:'center', maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 160px)'}}
              // className={!(props.hasBookmarks && props.hasBookmarks.length > 0) ? `card_body `+ props.result.expanded : (props.result.expanded) ? `card_body undefined` : `card_body expanded`}
              className={`card_body_generated_answer `}
              aria-expanded={containerIsExpanded ? true : false}
              >
              {/* <LineClamp className={containerIsExpanded ? '':'truncate'}
                dangerouslySetInnerHTML={{ __html:question }} /> */}
            </div>
            <div style={{display:'flex', justifyContent:'flex-end',alignItems: 'center'}}>
              {!isSmallScreen &&(
              <P p1 regular textoffblack ></P>)}
              <Sparkles style={{margin:'12px'}} color="#8771DF" height="23px" width="23px"></Sparkles>
            </div>
            </Row>)}
          </Container>
              {/* <P
                className={!containerIsExpanded ? "suggestedQuery_unexpanded p-left":"suggestedQuery p-left" }
                p1
                regular
                textoffblack
              >
                {"AI generated response"}
              </P> */}
              </>
          )}
        </SuggestedAnswer>

        {/* AI ASSISTED RESULTS */}
        {containerIsExpanded && aiAnswerReady && llmAnswers 
        && llmAnswers.map((result,i) => {

          return(
          <>
          <Container>
            {result &&  result.text &&(
            <Row>
              { !isSmallScreen ? 
            <pre id='typewriter' className=' px-3 pt-0 pb-2 my-1' style={{fontSize:'16px',lineHeight:'23px', whiteSpace:'break-spaces',maxHeight:'500px',width: '100%',overflowY: 'auto',  scrollbarColor: '#b7c3cc #e8e5f8'}} dangerouslySetInnerHTML={{__html:result.text}} ></pre>:
            <pre id='typewriter' className=' px-3 pt-0 pb-2 my-1' style={{ fontSize:'16px',lineHeight:'23px', whiteSpace:'break-spaces',maxHeight:'60vh',width: '100%',overflowY: 'auto',  scrollbarColor: '#b7c3cc #e8e5f8'}} dangerouslySetInnerHTML={{__html:result.text}} ></pre>
            }

            </Row>
            )}
            {result['doc_publisher'] && (
            <Row>
              <div  style={{display:"flex", flexDirection: "row", alignItems:'center', padding:'1% 3%'}}>
                <PublisherTag publisher={result['doc_publisher']} isFolder={false}
                      isPrivate={false} url={result['online_view_url'] } 
                      // encrypted_url={''}
                      requestToken={false} 
                      inMobileResult ={ isSmallScreen} ></PublisherTag>
               
                  <div style={{display:"flex", flexDirection:'column',gap:'7px',justifyContent:'center'}}>
                  { !isSmallScreen && result['title'] &&
                    <H2 h2 semibold>
                      <a
                        href={ (Object.hasOwn(result, 'online_pdf_url') && typeof result['online_pdf_url']!=="undefined" ) ? result['online_pdf_url'] :  result.online_view_url} // cam we have no isOnlineViewUrlPdf 
                        target="_blank"
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{ __html: result.title }} />
                    </H2> 
                   }
                  { !isSmallScreen && result['online_view_url'] &&
                    <A
                      c2
                      semibold
                      textlink
                      href={ result['online_view_url']}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result['online_view_url'] && result['online_view_url'].length < 50 ? (result['online_view_url']):(  result['online_view_url'].slice(0,50) +'...' ) }
                    </A> }

                  </div>
                
              </div>
              
            </Row>)}
            <Hr />

          </Container>
          </>)
                  }
)}
    </GeneratedResultsContainer>
  );
};

export default ExtractiveAnswer;
