import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import { removeUserSession } from '../../functions/manageSessions';
import { getUserAvatarFullName, getUserAvatarExtraInfo, getUserAvatarOrgAlias, goToDashboard } from '../../functions/utils';
import { useLocation, useHistory } from 'react-router-dom';
import useDocumentScrollThrottled from '../../functions/useDocumentScrollThrottled';
import SearchBar from '../search-bar';
import Button from '../button';
import ToggleButton from '../toggle-button';

import { H1, Label, Span } from '../../components/theme/typography';
import { ReactComponent as MedwiseLogoWithName } from '../../assets/svgs/medwise-logo-with-name.svg';
import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';
import { ReactComponent as MedwiseLogoWithNameBeta } from '../../assets/svgs/medwise-logo-beta-with-name.svg';

import { ReactComponent as ChevronIcon } from '../../assets/svgs/chevron.svg';
import Nav from './styles';
import LoadingIndicator from '../loading-indicator';
import CPDCredits from '../cpd-credit';
import { AppContext } from '../../context';
import FeatherIcon from 'feather-icons-react';
import HeaderMobile from '../headerMobile';
import BtnFiltersCard from './btn-filters/index.js';
import ModalSSOLogin from '../modal-sso-login';
import ChatSearchBar from '../search-bar/ChatSearchBar';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen.js';


const Header = ({ withSearchBar, withAccountButtons, landingPageSearchBar, withChatBar,landingPage }) => {
  const isSmallScreen = useIsSmallScreen();
  
  const [toggle, setToggle] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userData,setUserData] = useState(false);
  const [isAdminUser,setIsAdminUser] = useState(false);
  const [isMagicUser,setIsMagicUser] = useState(false);
  const [allowSignup,setAllowSignup] = useState(false);
  const [currentUserEmail,setCurrentUserEmail] =  useState(undefined);
  const [organisation, setOrganisation] = useState(undefined);
  const [organisationLogo, setOrganisationLogo] = useState(false);
  const [organisationUrlLogo, setOrganisationUrlLogo] = useState('');
  const [organisationColour, setOrganisationColour] = useState(false);
  const [organisationColourLeft, setOrganisationColourLeft] = useState(''); //useState('#fff');
  const [organisationColourRight, setOrganisationColourRight] =  useState('');//useState('#fff');
  const [organisationColourBar, setOrganisationColourBar] = useState(''); //useState('#8771df');
  const dashboardBarStyle = {
      "--bar-colour": organisationColourBar,
  };
  // const [showBookmarkBanner, setShowBookmarkBanner] = useState(false);
  // const [requestStopBanner, setRequestStopBanner] = useState(false);
  const [showSSOLoggedInModalFlag, setShowSSOLoggedInModalFlag] = useState(false);
  const [modalTitle, setModalTitle] = useState(undefined);
  const [loginLink, setLoginLink] = useState(undefined);
  const location = useLocation();
  const history = useHistory();
  const [timeOutId, setTimeOutId]= useState(undefined);
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source(); 
  const {setSearchContext,searchContext,userStats,setUserStats,setRequestUser,userOrganisationContext,setUserOrganisationContext,removeUserOrganisationContext,userContext,setUserContext,setRequestTrackerContext,setUserId,setSkipRequests} = useContext(AppContext);
  
  // Every time isLoggedin changes and at the 1st render, it controls whether the user is logged in or not and displays either account avatar or sign up & log in buttons
  // ! we may have prbl when users logs out with something other than logout button and when user is login and header does not re-render
  useEffect(() => {
    // let user_info =
    if (userContext && userContext['is_anonymous']) {
      setIsLoggedin(false);
      setIsMagicUser(false);
      setIsAdminUser(false);
    } else {
      if (userContext && userContext["email"]) {
        setIsLoggedin(true);
        if (userContext['org_name'] && !organisationLogo) {
          let truncated_name =userContext['org_name'].substring(0,32);
          if (truncated_name.length !== userContext['org_name'].length ) {
            setOrganisation(userContext['org_name'].substring(0,32)+ '...');
          } else {
            setOrganisation(userContext['org_name']);
          };
        };

        if (userContext['org-user-type']==="admin") {
          setIsAdminUser(true);
          setIsMagicUser(false);
        };

        if (userContext['org-user-type']==="magic"){
          setIsMagicUser(true);
          if (userContext['magic-signup-allowed']===false) {
            setAllowSignup(false);
          } else {
            setAllowSignup(true);
          }
        }
      }
    }  
  },[userContext.email]);

  useEffect(() => {
    // let user_info =
      if (userContext && userContext["email"]) {
        if (userContext['org_name'] ) {
          let truncated_name =userContext['org_name'].substring(0,32);
          if (truncated_name.length !== userContext['org_name'].length ) {
            setOrganisation(userContext['org_name'].substring(0,32)+ '...');
          } else {
            setOrganisation(userContext['org_name']);
          };
        }
        // no org 
        else {
          setOrganisation(undefined);
          setOrganisationLogo(false);
          setOrganisationUrlLogo('');
          setOrganisationColour(false);
          setOrganisationColourBar('');
          setOrganisationColourLeft('');
          setOrganisationColourRight('');
        }// org logo no org ? 
      }
  },[userContext]);

  useEffect(()=>{
    if (userOrganisationContext && userOrganisationContext['logo_path']  && userOrganisationContext['logo_path'] !== null ) {
      if (userOrganisationContext['logo_path'] === 'name'){
        setOrganisationLogo(false);
        setOrganisationUrlLogo('');
      }
      else{
        setOrganisationLogo(true);
        setOrganisationUrlLogo(userOrganisationContext['logo_path']);
      }
    }
    if (!organisationColour && userOrganisationContext && userOrganisationContext['colours'] && (userOrganisationContext['colours']['left'] !== null || userOrganisationContext['colours']['right'] !== null)) {
      setOrganisationColour(true);
      if ((userOrganisationContext['colours']['left'] !== null) && (userOrganisationContext['colours']['left'] !== '')) {
        setOrganisationColourLeft(userOrganisationContext['colours']['left']);
      }
      if ((userOrganisationContext['colours']['right'] !== null) && (userOrganisationContext['colours']['right'] !== '')) {
        setOrganisationColourRight(userOrganisationContext['colours']['right']);
      }
    }
    if ( userOrganisationContext && userOrganisationContext['colours'] && userOrganisationContext['colours']['dashboard_bar'] && (userOrganisationContext['colours']['dashboard_bar'] !== null) && (userOrganisationContext['colours']['dashboard_bar'] !== '')) {
      setOrganisationColourBar(userOrganisationContext['colours']['dashboard_bar']);
    }
  },[userOrganisationContext,organisation]);

  // Header hide/show on scroll functionality is inspired by https://medium.com/mtholla/create-a-transitioning-header-using-react-hooks-like-mediums-c70ed211f7c9
  // Via custom function: functions/useDocumentScrollThrottled.js
  // const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  // const MINIMUM_SCROLL = 50;
  // const TIMEOUT_DELAY = 100;

  // useDocumentScrollThrottled((callbackData) => {
  //   const { previousScrollTop, currentScrollTop } = callbackData;
  //   const isScrolledDown = previousScrollTop < currentScrollTop;
  //   const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

  //   let id= setTimeout(() => {
  //     setShouldHideHeader(isScrolledDown && isMinimumScrolled);
  //   }, TIMEOUT_DELAY);
  //   setTimeOutId(id);
  // });

  // toggle menu visibility when the menu button is clicked
  // const toggleMenu = () => {
  //   setIsMenuVisible(!isMenuVisible);
  // };
  
  // hide menu when the user scrolls the page
  const handleScroll = () => {
    // if (isMenuVisible) {
    setIsMenuVisible(false);
    // };
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    
    // return () =>
    //   window.removeEventListener('scroll', handleDocumentScrollThrottled);
    // eslint-disable-next-line
  }, []);

  // const hiddenStyle = shouldHideHeader ? 'hidden' : '';
  // const hiddenStyle = isMenuVisible ? '' : 'hidden';

  // Lock the body scroll when the mobile menu is active
  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  });

  //clean timeOut 
  useEffect(() => () => {
    if (typeof timeOutId !== "undefined"){
      clearTimeout(timeOutId);
    }
  });

  //
  useEffect(() => () => {
    let tempUserData = userContext;

    if( tempUserData || userContext['email'] ){
      setUserData(true); 
    }
  },[currentUserEmail]);

  const handleLogout = () => {
    setRequestTrackerContext(prev => { return {...prev, 'logout-user':true}});
    setSkipRequests(true);
    setUserId(99);// cancel and skip request   
    console.log('217  log request started ');
    axios
      .get(`${window.BASE_URL_USER}logout-user`, {withCredentials: true})
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        if (res.data.success) {
          // removeUserSession();
          // setUserContext({email:''});
          setSearchContext({...searchContext,q:undefined});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          removeUserOrganisationContext();
          // setUserOrganisationContext(undefined);
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          setIsLoggedin(false);
          setOrganisation(undefined);
          setOrganisationLogo(false);
          setOrganisationColourLeft('#fff');
          setOrganisationColourRight('#fff');
          setOrganisationColourBar('#fff');
          setOrganisationUrlLogo('');
          history.push('/');
        } else {
          console.log(
            'The request is successful, but for some reason res.data.success is not received: \n',
            res
          );
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          removeUserOrganisationContext();
          // setUserOrganisationContext(undefined);
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          setIsLoggedin(false);
          setOrganisation(undefined);
          setOrganisationLogo(false);
          setOrganisationColourLeft('#fff');
          setOrganisationColourRight('#fff');
          setOrganisationColourBar('#fff');
          setOrganisationUrlLogo('');
          history.push('/');
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        setUserId(0);
        setSkipRequests(false);
        console.log(error);
      });
  };

  const handleSignup = () => {
    history.push('/signup');
  };

  const handleLogin = () => {
    history.push('/login');
  };

  const handleFeedback = () => {
    history.push("/dashboard/users_feedback.html");
  };

  // handle logout for magic user signup
  // we wait for user to be logout before redirrecting
  const handleLogoutmagicSignup = () => {
    history.push('/signup');
    
  };

  const handleLogoutmagicLogin = () => {
    history.push('/login');
  };

  const handleShowNHS = () => {
    setShowSSOLoggedInModalFlag(true);
    setModalTitle("Continue with NHS login");
    setLoginLink("https://users.medwise.ai/nhslogin?sso=true");
  }; 

  // initiates request for stop-banner
  // const removeBanner = function(){
  //   setShowBookmarkBanner(false);
  //   setRequestTrackerContext(prev => { return {...prev, 'stop-banner':true}});
  //   axios({
  //       method: 'POST',
  //       url: window.BASE_URL_USER+'stop-banner',
  //       withCredentials: true,
  //       signal: controller.signal,
  //       cancelToken: source.token,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       data: JSON.stringify({
  //         name:'bookmark'
  //       })

  //   })
  //       .then((res) => {
  //         setRequestTrackerContext(prev => { return {...prev, 'stop-banner':false}});
  //         setRequestStopBanner(false);
  //       })
  //       .catch((error) => {
  //           setRequestTrackerContext(prev => { return {...prev, 'stop-banner':false}});
  //           console.log(error);
  //       });
  // };

  // useEffect (()=>{
  //   if(requestStopBanner){
  //     removeBanner();
  //   }
  // },[requestStopBanner]);
  
  const UserAccountDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={ "navigation_account--toggle1 d-md-block " }
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        setIsMenuVisible(!isMenuVisible);
        onClick(e);
      }}
    >

      {(isLoggedin && !withSearchBar && !withChatBar) && (
        <div id="nav_account_info">
          {isMagicUser ? (
            <div id="nav_account_name">{getUserAvatarOrgAlias(userOrganisationContext)}</div>
          ) : (
            <div id="nav_account_name">{getUserAvatarFullName()}</div>
          )}
          {isAdminUser ? (
            <div id="nav_account_location">Admin</div>
          ) : (
            <div id="nav_account_location">{getUserAvatarExtraInfo()}</div>
          )}
        </div>
      )}

      {(isLoggedin && (withSearchBar || withChatBar)) && (
        <div id="nav_account_info_search_bar">
          {isMagicUser ? (
            <div id="nav_account_name" className='with_search_bar'>{getUserAvatarOrgAlias(userOrganisationContext)}</div>
          ) : (
            <div id="nav_account_name" className='with_search_bar'>{getUserAvatarFullName()}</div>
          )}
          {isAdminUser ? (
            <div id="nav_account_location" className='with_search_bar'>Admin</div>
          ) : (
            <div id="nav_account_location" className='with_search_bar'>{getUserAvatarExtraInfo()}</div>
          )}
        </div>
      )}
      
      <ChevronIcon id="nav_account_svg" />
    </div>
  ));

  const getDropDownMenu = () => {
    let menu ; 
    if (isMagicUser) { // option 8
      if (allowSignup) {
        menu =     
        <div className="navigation_links" style={{top: withSearchBar && "0"}}>
          {(isLoggedin && organisation && organisationUrlLogo && !withSearchBar && !withChatBar) && (
          <span id="org_img">
            <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
          </span>
          )}
          {(isLoggedin && organisation && !organisationUrlLogo && !withSearchBar) && (
            <span id="org_name" className="text-center align-top">{organisation}</span>
          )}

          {(isLoggedin && organisation && organisationUrlLogo && (withSearchBar || withChatBar)) && (
            <span id="org_img_search_bar">
              <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
            </span>
          )}
          {(isLoggedin && organisation && !organisationUrlLogo && withSearchBar) && (
            <span id="org_name_search_bar_div">
              <span id="org_name_search_bar" className="text-center align-top">{organisation}</span>
            </span>
          )}
          <Dropdown className="navigation_account">
            {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
              <span className="red-dot"></span>
            )}
            <Dropdown.Toggle as={UserAccountDropdown}/>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=> handleSignup()}>Sign up</Dropdown.Item>
              <Dropdown.Item onClick={()=> handleLogin()}>Log in</Dropdown.Item>
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"} rel="noopener noreferrer">Feedback
                {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                  <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
                )}
              </Dropdown.Item>
              <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
              <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleLogout()}>Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>;
      } else {
        menu =     
      <div className="navigation_links" style={{top: withSearchBar && "0"}}>      
       {(isLoggedin && organisation && organisationUrlLogo && !withSearchBar && !withChatBar) && (
          <span id="org_img">
            <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
          </span>
          )}
          {(isLoggedin && organisation && !organisationUrlLogo && !withSearchBar) && (
            <span id="org_name" className="text-center align-top">{organisation}</span>
          )}

          {(isLoggedin && organisation && organisationUrlLogo && (withSearchBar || withChatBar)) && (
            <span id="org_img_search_bar">
              <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
            </span>
          )}
          {(isLoggedin && organisation && !organisationUrlLogo && withSearchBar) && (
            <span id="org_name_search_bar_div">
              <span id="org_name_search_bar" className="text-center align-top">{organisation}</span>
            </span>
          )}
          <Dropdown className="navigation_account">
            {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
              <span className="red-dot"></span>
            )} 
            <Dropdown.Toggle as={UserAccountDropdown}/>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=> handleLogin()}>Log in</Dropdown.Item>
              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"} rel="noopener noreferrer">Feedback 
                {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                  <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
                )}
              </Dropdown.Item>
              <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
              <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleLogout()}>Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>;
      };
    } else { // option 9
      menu =   
      <div className="navigation_links" style={{top: withSearchBar && "0"}}>            
        {(isLoggedin && organisation && organisationUrlLogo && !withSearchBar && !withChatBar) && (
          <span id="org_img">
            <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
          </span>
        )}
        {(isLoggedin && organisation && !organisationUrlLogo && !withSearchBar) && (
          <span id="org_name" className="text-center align-top">{organisation}</span>
        )}

        {(isLoggedin && organisation && organisationUrlLogo && (withSearchBar || withChatBar)) && (
          <span id="org_img_search_bar">
            <img src={organisationUrlLogo} srcSet={organisationUrlLogo} alt="" id="nav_account_img"></img>
          </span>
        )}
        {(isLoggedin && organisation && !organisationUrlLogo && withSearchBar) && (
          <span id="org_name_search_bar_div">
            <span id="org_name_search_bar" className="text-center align-top">{organisation}</span>
          </span>
        )}
        <Dropdown className="navigation_account">
          {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
            <span className="red-dot"></span>
          )}
          <Dropdown.Toggle as={UserAccountDropdown}/>
            <Dropdown.Menu >
              <Dropdown.Item href="/account">Account</Dropdown.Item>

              {isAdminUser &&(
                <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/org_summary.html"}> Dashboard </Dropdown.Item>
              )}
              {!isAdminUser &&(
                <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/index.html"}> Dashboard </Dropdown.Item>
              )}

              <Dropdown.Item href={window.BASE_URL_DASHBOARD+"dashboard/users_feedback.html"}> Feedback 
                {userStats && userStats.unread_feedback && userStats.unread_feedback !== 0 && (
                  <span className="feedback-dropdown-num">{userStats.unread_feedback}</span>
                )}
              </Dropdown.Item>

              <Dropdown.Item href="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" rel="noopener noreferrer" target="_blank" >Support</Dropdown.Item>
              
              <Dropdown.Item href='http://about.medwise.ai/' rel="noopener noreferrer" target="_blank" >About us</Dropdown.Item>

              <Dropdown.Item onClick={()=>handleLogout()}>Log out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      </div>;
    }


  return menu;
  }; 

  const goToLanding = () =>{
    history.push('/');
  };
  

  return (
    <>
    {landingPage && !isSmallScreen&& (
      <div style={{'position':'fixed', 'top':'0',"borderBottom":"solid 1px #DFE2E5", "backgroundColor":"#FFFFFF",'width':'100%','zIndex':'998'}}> 
          <div style={{ 'position':'relative',"color": "#212452","fontFamily":"Mulish,sans-serif","fontSize": "12px", "padding": "1px", "textAlign": "center" }}>
          This site is intended for healthcare professionals</div>
          </div>
    ) }
   
        {landingPage && (isSmallScreen) && (
      <div  style={{'position':'absolute', 'top':'0',"borderBottom":"solid 1px #DFE2E5", "backgroundColor":"#FFFFFF",'width':'100%','zIndex':'998'}}>         
        <div style={{ 'position':'relative',"color": "#212452","fontFamily":"Mulish,sans-serif","fontSize": "12px", "padding": "1px", "textAlign": "center" }}>
          This site is intended for healthcare professionals</div>
          </div>
    ) }

  {(isSmallScreen)  ? <HeaderMobile withSearchBar={withSearchBar} landingPageSearchBar={landingPageSearchBar} landingPage={landingPage}/>:    
    <Nav 
      style={{backgroundImage: (isLoggedin && organisationColour) && `linear-gradient(to right, ${organisationColourLeft}, ${organisationColourRight})` ,
      height: withSearchBar && "89px" , top: landingPage?'24px':'0px' }}
      withSearchBar={withSearchBar ? withSearchBar : undefined}  
    >
      <div className="navigation_logoAndToggle">
        {withChatBar ? (
          <a href={window.BASE_URL_DASHBOARD} onClick={goToLanding} className='logoWithName'>
            <MedwiseLogoWithNameBeta />
          </a>
        ) : (
          <a href={window.BASE_URL_DASHBOARD} onClick={goToLanding} className='logoWithName'>
            <MedwiseLogoWithName/>
          </a>
        )}
        <a href={window.BASE_URL_DASHBOARD} onClick={goToLanding}  className='logoWithoutName'>
          <MedwiseLogo/>
        </a>
        <Row >
          {(!isLoggedin && (!isSmallScreen)&& !withSearchBar && !withChatBar) && (
            <button id="redirect-dashboard" className="btn" onClick={goToDashboard} style={dashboardBarStyle}>
              <FeatherIcon icon="layout" className="mr-2" />
              <span id="my-dashboard">
                <span style={{lineHeight: "21px"}}>My Dashboard</span>
                <CPDCredits isAnonymous= {!(isLoggedin || isMagicUser)}  />
              </span>
            </button>
          )}

          {(!isLoggedin && (!isSmallScreen) && (withSearchBar || withChatBar)) && (
            <button id="redirect-dashboard-search-bar" className="btn" onClick={goToDashboard} style={dashboardBarStyle}>
              <FeatherIcon icon="layout" className="mr-2" />
              <span id="my-dashboard">
                <span style={{lineHeight: "21px"}}>My Dashboard</span>
                <CPDCredits isAnonymous= {!(isLoggedin || isMagicUser)}  />
              </span>
            </button>
          )}

          {(isLoggedin && (!isSmallScreen) && !withSearchBar && !withChatBar) && (
            <button id="redirect-dashboard" className="btn" onClick={goToDashboard} style={dashboardBarStyle}>
              <FeatherIcon icon="layout" className="mr-2" />
              <span id="my-dashboard">
                <span style={{lineHeight: "21px"}}>My Dashboard</span>
                <CPDCredits isAnonymous= {!(isLoggedin || isMagicUser)}  />
              </span>
            </button>
          )}

          {(isLoggedin && (!isSmallScreen) && (withSearchBar || withChatBar)) && (
            <button id="redirect-dashboard-search-bar" className="btn" onClick={goToDashboard} style={dashboardBarStyle}>
              <FeatherIcon icon="layout" className="mr-2" />
              <span id="my-dashboard">
                <span style={{lineHeight: "21px"}}>My Dashboard</span>
                <CPDCredits isAnonymous= {!(isLoggedin || isMagicUser)}  />
              </span>
            </button>
          )}

          <Col className='loaderContainter' ><LoadingIndicator/></Col>
        </Row>
      </div>
      { (!isLoggedin || isMagicUser || isSmallScreen) && (
      <div className="navigation_toggle" > 
        <ToggleButton toggle={toggle} setToggle={setToggle} />
      </div>
      )}
      { (isLoggedin  && (!isSmallScreen) && !isMagicUser) && (
      <div className="navigation_toggle_login" > 
        <ToggleButton toggle={toggle} setToggle={setToggle} />
      </div>
      )}
      {isSmallScreen && !toggle && organisation && organisationLogo && !withSearchBar &&  (
        <Container style={{zIndex:-1}}>
        <Row>
          <Col md={{ span: 8, offset: 3 }} >
            <div className={"navigation_org_logo_mobile"} >
            <img
              src={organisationUrlLogo}
              srcSet={organisationUrlLogo}
              alt= {organisation +"logo"}  
              width={'115px'}
              height={'56px'} 
             />
            </div>       
          </Col>
        </Row>
      </Container>
        )}
      {isSmallScreen&& !toggle && organisation && !organisationLogo && !withSearchBar &&  (
        <Container style={{zIndex:-1}}>
        <Row>
          <Col md={{ span: 8, offset: 3 }} >
          <div className={"navigation_org_logo_mobile"} >
          <H1 h1 regular textoffblack>{organisation}</H1>
          </div>       
          </Col>
        </Row>
      </Container>   
        )}
      {withSearchBar && !toggle && (
        <Container fluid={'xl'}>
          <Row>
            <Col md={{ span: 8, offset: 3 }} 
            className={(landingPageSearchBar ? 'd-block d-sm-block d-md-none' : '') + " navigation_searchbar"}>
              <SearchBar isAtHeader={true} />
            </Col>
            <Col md={{ span: 8, offset: 3 }} className="filter-tabs">
              <BtnFiltersCard/>
            </Col>
          </Row>
        </Container>
      )}
      {withChatBar && !toggle && (
        <Container fluid={'xl'}>
          <Row>
            <Col md={{ span: 8, offset: 3 }} 
            className={(landingPageSearchBar ? 'd-block d-sm-block d-md-none' : '') + " navigation_searchbar"}>
              <ChatSearchBar isAtHeader={true} />
            </Col>
          </Row>
        </Container>
      )}
      {/* the whole right menu is bellow */}
      {((!isSmallScreen) || toggle) &&(
        <>
        {toggle &&(
          <div className="navigation_mobileMenuOverlay"></div>)}
          {!isLoggedin && withSearchBar && (!isSmallScreen) && !toggle && ( // option 1 (<=1380px && withSearchBar)
              <div className="navigation_links login_button_intermediary_screens" style={{top: '16px'}}>
                <ul>
                  <li className={toggle ? 'anony-nhs-log-in-mobile' : 'anony-nhs-log-in'} style={{height: "32px"}}>
                    <Button
                      type="external_link_nhs"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colornhsheader
                      buttonsmall
                      // to="https://users.medwise.ai/nhslogin?sso=true"
                      width="96px"
                      onClick={() => {  
                        handleShowNHS();
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>

                  <li id="anony-log-in">
                    <Button
                      type="link"
                      colorsecondary
                      buttonsmall
                      to="/login"
                      text="Log in"
                      width="96px"
                      onClick={() => {  
                        if(isSmallScreen){
                          setToggle(!toggle);
                        }
                      }}
                    />
                  </li>

                  <li id="anony-sign-up">
                    <Button
                      type="link"
                      colorprimary
                      buttonsmall
                      to="/signup"
                      text="Sign up"
                      width="96px"
                      onClick={() => {  
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>
                </ul>
              </div>
          )}
          <div
              //  className="navigation_links"
               className={(toggle ? 'navigation_links_toggle' : 'navigation_links') }>
            <ul style={{height: "32px"}}>
               {/*
               Show login and sign up  
               with account buttons from different products (no longer it use) and is not logged in */}
              {withAccountButtons && !isLoggedin && (!withSearchBar || (withSearchBar &&( isSmallScreen))) &&  ( // option 2
              // {withAccountButtons && (!isLoggedin || isMagicUser ) && (
                <>
                  {/* <li style={{height: "32px", display: toggle && "none"}}> */}
                  <li className={toggle ? 'anony-nhs-log-in-mobile' : 'anony-nhs-log-in'} style={{height: toggle ? "48px": "32px"}}>
                    <Button
                      type="external_link_nhs"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colornhsheader={toggle ? 0 : 1}
                      colornhsheadermobile={toggle ? 1 : 0}
                      buttonsmall
                      // to="https://users.medwise.ai/nhslogin?sso=true"
                      width="96px"
                      onClick={() => {  
                        handleShowNHS();
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>
                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      to="/login"
                      text="Log in"
                      width="96px"
                      onClick={() => {  
                            if(isSmallScreen){
                              setToggle(!toggle);
                            } }}
                    />
                  </li>
                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      to="/signup"
                      text="Sign up"
                      width="96px"
                      onClick={() => {  
                        if(isSmallScreen){
                          setToggle(!toggle);
                        } }}
                    />
                  </li>
                </>
              )}
              {/*
                Show logout &&  account
                Below block outputs "Account" and "Log out" buttons on mobile. (Account button is ignored if user is already on the "/account" route) */}
              {/* Note that: There isn't "Avatar" on mobile */}
              {/* {isLoggedin && isSmallScreen && ( */}
              {isLoggedin && (isSmallScreen )&& !isMagicUser && (
                <>
                  {/* show acount  if not in account and not in magicuser */}
                  {location.pathname !== '/account' && ( // option 3
                    <li>
                      <Button
                        type="link"
                        colorsecondary
                        buttonsmall
                        to="/account"
                        text="Account"
                        width="152px"
                        onClick={() => setToggle(!toggle)}
                      />
                    </li>
                  )}
                  {/* show logout only if not magic user 
                    TO DO : for isMagic user jus the support btn
                     
                  */}
                  {/* {isMagicUser && (
                    <>
                    <li>
                    <Button
                        type="external_link"
                        colorsecondary
                        buttonsmall
                        text="Support"
                        width="90px"
                        to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" 
                        rel="noopener noreferrer" 
                        target="_blank"
                      />
                    </li></>)} */}
                  
              
                    {/* option 4 */}
                    <li>
                    <Button
                        type="external_link"
                        colorprimary
                        buttonsmall
                        text="Support"
                        width="90px"
                        to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce" 
                        rel="noopener noreferrer" 
                        target="_blank"
                      />
                    </li>

                    {/* we used a different About us button for mobile loggedin and  not login */}
                    <li>
                      <Button
                        type="link"
                        colorsecondary
                        buttonsmall
                        text="About us"
                        width="90px"
                        to= 'http://about.medwise.ai/'
                        target="_blank"
                      />
                    </li>

                    <li>
                      <Button
                        type="link"
                        colorprimary
                        buttonsmall
                        to="#"
                        text="Log out"
                        width="152px"
                        onClick={() => handleLogout()}
                      />
                    </li>
                </>
              )}
              {/* we used a different About us button for mobile loggedin and  not login */ }
              {/* {isSmallScreen && isLoggedin && !isMagicUser &&(
              <>
                <li>
                  <Button
                      type="link"
                      colorsecondary
                      buttonsmall
                      text="About us5"
                      width="90px"
                      to= 'http://about.medwise.ai/'
                  />
                </li>
                </>
              )} */}
              {(isSmallScreen) && !isLoggedin && !isMagicUser &&( // option 6
                <li>
                  <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      text="About us"
                      width="90px"
                      to= 'http://about.medwise.ai/'

                  />
                </li>
              )}
              {/* TODO:  Refactoring */}
              {/* show organisation name without searchbar when it is next to dropdown menu */}
                {/* {!isSmallScreen && organisation && 
                  isLoggedin &&  !withSearchBar &&
                  organisationUrlLogo==='' && !isMagicUser&& (
                <li className='organisationName'>
                    <Label >
                      {organisation}
                    </Label>
                </li>
                )} */}
                {/* show organisation name without searchbar when it is next to dropdown menu */}
                {/* {!isSmallScreen && organisation && 
                  isLoggedin &&  !withSearchBar &&
                  organisationUrlLogo==='' && isMagicUser && (
                <li className='organisationNameMagicUser'>
                    <Label >
                      {organisation}
                    </Label>
                </li>
                )} */}

                {/* { organisationLogo  && organisation&& !isSmallScreen && 
                  isLoggedin  && !isMagicUser  && !withSearchBar &&(
                  <li className='organisationLogo'>
                  <img
                    src={organisationUrlLogo}
                    srcSet={organisationUrlLogo}
                    //  xmlns={organisationUrlLogo}
                     alt= {organisation +"logo"}
                     width={'115px'}
                     height={'56px'} /> 
                  </li>
                )} */}
                 {/* { organisationLogo  && organisation && !isSmallScreen && 
                  isLoggedin && isMagicUser && !withSearchBar &&(
                  <li className='organisationLogoMagic'>
                    <img
                      src={organisationUrlLogo}
                      srcSet={organisationUrlLogo}
                      //  xmlns={organisationUrlLogo}
                      alt= {organisation +"logo"}
                      width={'115px'}
                      height={'56px'} /> 
                  </li>
                )} */}
                {/* show organisation name with searchbar 
                  when url has not being loaded we get  orgUrlLogo=''
                  if it has been set we have orgUrlLogo != '' 
                */}
                {/* { !isSmallScreen && organisation  && withSearchBar &&
                  isLoggedin && !isMagicUser &&  (
                <li className='organisationNameWithSearchBar'>
                    <Label>{organisation}</Label>
                </li>)}
                { !isSmallScreen && organisation  && withSearchBar &&
                  isLoggedin && isMagicUser && (
                <li className='organisationNameWithSearchBarMagicUser'>
                    <Label>{organisation}</Label>
                </li>)} */}
                {/* show organisation name with searchbar */}
                {/* {!isSmallScreen && organisationLogo
                  && withSearchBar && isLoggedin && !isMagicUser &&(
                  <li className='organisationLogoWithSearchBar'>
                    <img
                     src={organisationUrlLogo}
                     srcSet={organisationUrlLogo}
                     alt= {organisation +"logo"}  
                     width={'115px'}
                     height={'56px'}/> 
                </li>)}
                {!isSmallScreen && organisationLogo
                  && withSearchBar && isLoggedin && isMagicUser &&(
                  <li className='organisationLogoWithSearchBarMagicUser'>
                    <img
                     src={organisationUrlLogo}
                     srcSet={organisationUrlLogo}
                     alt= {organisation +"logo"}  
                     width={'115px'}
                     height={'56px'}/> 
                </li>)} */}

              {/* show signup and logout for magic user  */}
              {/* {withAccountButtons && isLoggedin  && toggle &&  (isMagicUser || !isSmallScreen) &&  ( */}
              {withAccountButtons && isLoggedin  && toggle && isMagicUser &&  ( // option 7
                <>
                  {allowSignup && (
                    <li>
                      <Button
                        type="link"
                        className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                        colorsecondary
                        buttonsmall
                        to="#"
                        text="Sign up"
                        width="96px"
                        onClick={()=>handleLogoutmagicSignup()}
                      />
                    </li>
                  )}

                  <li>
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorprimary={(allowSignup ? 1 : 0) }
                      colorsecondary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      to="#"
                      text="Log in"
                      width="96px"
                      onClick={()=>handleLogoutmagicLogin()}
                    />
                  </li>
                  
                  <li>
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorsecondary={(allowSignup ? 1 : 0) }
                      colorprimary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce"
                      text="Support"
                      width="96px"
                      rel="noopener noreferrer" 
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary={(allowSignup ? 1 : 0) }
                      colorsecondary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      text="About us"
                      width="96px"
                      to='http://about.medwise.ai/'
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary={(allowSignup ? 1 : 0) }
                      colorprimary={(allowSignup ? 0 : 1) }
                      buttonsmall
                      text="Log out"
                      width="96px"
                      to="#"
                      onClick={() => handleLogout()}
                      />
                  </li>
                </>
              )}

              {/* logged-in users hamburger menu */}
              {isLoggedin  && toggle && !isMagicUser && !isSmallScreen &&  ( // option 5
                <>
                  {/* show acount  if not in account and not in magicuser */}
                  {location.pathname !== '/account' && (
                    <li>
                      <Button
                        type="link"
                        className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                        colorsecondary
                        buttonsmall
                        to="/account"
                        text="Account"
                        width="96px"
                        onClick={() => setToggle(!toggle)}
                      />
                    </li>
                  )}

                  <li>
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? "" : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      to="https://medwise.notion.site/Medwise-Support-Documentation-95aff95588e94612947ac90d2d1a52ce"
                      text="Support"
                      width="96px"
                      rel="noopener noreferrer" 
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="external_link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorsecondary
                      buttonsmall
                      text="About us"
                      width="96px"
                      to='http://about.medwise.ai/'
                      target="_blank"
                    />
                  </li>

                  <li >
                    <Button
                      type="link"
                      className={(landingPageSearchBar ? '' : 'navigation_searchbar') }
                      colorprimary
                      buttonsmall
                      text="Log out"
                      width="96px"
                      to="#"
                      onClick={() => handleLogout()}
                      />
                  </li>
                </>
              )}
            </ul>
          </div>
          {/* Below block outputs "User avatar", "Account" and "Log out" dropdowns on desktop. (Account option is ignored if user is already on the "/account" route) */}
          {/* Note that: "Avatar" is rendered by UserAccountDropdown component */}
          {isLoggedin && !isSmallScreen && (
              getDropDownMenu()            
          )}
        </>
      )}
    </Nav>}

    {showSSOLoggedInModalFlag && (
      <ModalSSOLogin
        modalFeedbackShow={showSSOLoggedInModalFlag}
        modalFeedbackClose={setShowSSOLoggedInModalFlag} 
        title={modalTitle}
        link={loginLink}
      />  
    )}
    </>
  );
};

export default Header;
