import React, {useContext, useEffect, useState, Suspense, lazy, useMemo,useRef} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen';
import { AppContext } from '../../../context';
import Hr from '../../hr';

import { H2, H1, P, A, Span } from '../../theme/typography';
import { Article,Styles } from './styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { product_name } from '../../../functions/frontend_variables';
import axios from 'axios';
import CpdNotes from '../CpdNotes';
import ModalSearchFeedback from '../../modal-search-feedback';
import TypewriterAnimationChat from '../../typewriter/TypewriterAnimationChat';
import ModalPDFPreview from '../../modal-pdf-preview';

import PublisherTag from '../../publisher-tag';
import PDFPreview from '../PDFPreview';

// const PDFPreview = lazy(() => import("../PDFPreview"));
// const ModalPDFPreview = lazy(() => import("../../modal-pdf-preview"));

const ResultCard = (props) => {
  const { 
    userContext,
    feedbackContext, 
    setFeedbackContext,
    searchContext,
  } = useContext(
    AppContext
  );
  const isSmallScreen = useIsSmallScreen();
  const [requestExpiryToken,setRequestExpiryToken] = useState('');
  const [urlPdf,setUrlPdf] = useState('');
  const [url,setUrl] = useState('');
  const [iframeAllowed, setIframeAllowed] = useState(false);
  const [cardExpanded, setCardExpanded] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [showPDFModalFlag, setShowPDFModalFlag] = useState(false);
  const AnnotationsRef = useRef(null);


  const callbackResultExpanded = (result) => {
    return () => {
      setCardExpanded(prev => {return true;});
      // result.expanded = 'expanded';
      // result.noteExpanded = true;
    };
  };

  var onlineViewUrlParts = [];
  onlineViewUrlParts = props.result.online_view_url.split("/");
  onlineViewUrlParts = onlineViewUrlParts.filter(part => {
    return part !== '';
  });
  onlineViewUrlParts[1] = onlineViewUrlParts[0] + "//" + onlineViewUrlParts[1];
  onlineViewUrlParts.shift();
  var webOnlineViewUrl = onlineViewUrlParts.join(" › ");
  if (webOnlineViewUrl.length > 56)
    webOnlineViewUrl = webOnlineViewUrl.substring(0,53) + "...";


  const isToday = (date) =>  {
    const today = new Date();
  
    if(today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate())
      return true;
    return false;
  };

  const isYesterday = (date)=>  {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if(yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate())
      return true;
    return false;
  };

  const formattedDate = (d) => {
    const date = new Date(d);
    const today = new Date();
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate())
      return 'Today';

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.getFullYear() === date.getFullYear() && yesterday.getMonth() === date.getMonth() && yesterday.getDate() === date.getDate())
      return 'Yesterday';

    const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    return date.getDate() + ' ' + monthAbbr[date.getMonth()] + ' ' + date.getFullYear();
  };

  // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link 
  // TODO: /log-dialog-interaction
  const handleDownload = (e, result) => {
    e.stopPropagation();
    let facets =  searchContext.facets.map(x=>x.name);
    let body = { 
      // chunk_id: result.chunk_id,
      online_view_url:result.online_view_url, // probably should add 'online_pdf_url' 
      // requested_obj:result.requested_obj,
      // "selected-filters": facets,
      // doc_publisher:result.doc_publisher,
      // rank: result.rank,
      // product: product_name,
      "interaction_type": "visit"};
      // if result has private , we check if the source needs an /get-expiry-token 
      if( result.from && result.from === 'private'){
          if (result.requested_obj){
            e.preventDefault();
            setUrl(result.online_view_url);
            setUrlPdf(result.online_view_url); // TO DO CHECK THESE 2 VARIABLES
            setRequestExpiryToken(result.requested_obj);
          }
          // url comes form private but it is not a private src
          // so no need to request token 
          else {
            if(result.online_view_url){
              setUrl(result.online_view_url);
              setUrlPdf(result.online_view_url);
              e.preventDefault();
              if( Object.hasOwn(result, 'online_pdf_url') && typeof result['online_pdf_url']!=="undefined" ){
                window.open(result.online_pdf_url,'_blank','noreferrer');
              }
              else {
                window.open(result.online_view_url,'_blank','noreferrer');
              }
            }
          }
             
        // }
    }
    // TO DO move to useEffect 
    // request should be in useEffect only to allow for cancel and clean up
    if( searchContext.q){
      body = {
        ...body,
        // chunk_id: result.chunk_id,
        // chunk_title: title, 
        // online_view_url: result.online_view_url,
        // requested_obj:result.requested_obj,
        // "selected-filters": facets,
        // doc_publisher:result.doc_publisher,
        // rank: result.rank,
        // product: product_name,
        // event:"visited",
        q: searchContext.q}; }
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials : true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };

  // Important note: First result is coded inline (made use of the map method)
  const handleCardClick = (e, result, i) => {
    let cardBody = e.currentTarget.querySelector('.card_body');
    if (!cardExpanded) {
      // result.expanded = 'expanded';
      setCardExpanded(prev=> {return true;});
      setFeedbackContext({
        ...feedbackContext,
        chunk_id: result.chunk_id,
        chunk_title:result.chunk_title,
        online_view_url: result.online_view_url,//TODO  should we add the field online_pdf_url ? 
        requested_obj: result.requested_obj,
        chunk_text: result.text,
        doc_publisher: result.doc_publisher,
        rank: i,
        value: 1,
        feedbackType: 'interact',
        triggerRequest: true,
      });
    } else{
      if (isSmallScreen){
        // result.expanded = undefined;
        setCardExpanded(false);
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,//TODO  should we add the field online_pdf_url ? 
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: false,
        });
      }
    }
  };

  
  const handleUnexpanded = (e, result, i) => {
    setCardExpanded(prev => {return false;});
    // result.expanded = undefined;
    result.noteExpanded = false;
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url, //TODO  should we add the field online_pdf_url ? 
      chunk_text:  result.text,
      doc_publisher: result.doc_publisher,
      rank: i,
      value: 1,
      feedbackType: 'interact',
      triggerRequest: false,
    });
  };

  useEffect (() => {
    if (props.isOnlineViewUrlPdf  && (!("preview" in props.result) || (props.result.preview === null))) {
      //add axios and add-log and redirrect to link
      // if userContext is more than 20 min old, refresh by calling /get-summary before proceeding
      const config = {
        method: 'post',
        url: `${window.BASE_URL_DOCUMENTS}iframe-allowed`,
        withCredentials : true,
        headers: {'Content-Type': 'application/json' },
        data: JSON.stringify({
          'url': props.result.online_view_url,//TO DO probably need to change to props.result['online_pdf_url']
          'search_params': userContext.search_params,
        }),
      };
  
      axios(config)
      .then(function(res){
        if (res.data.allowed) {
          setIframeAllowed(true);
        } else {
          setIframeAllowed(false);
        };
      })
      .catch (function(error){
        console.log('error is: \n', error);
        setIframeAllowed(false);
      });
    };
  },[]);
  
  useEffect (() => {
    if (requestExpiryToken !== '' && url !== '' )
    {
      //add axios and add-log and redirrect to link
      // const config = {
      //   method: 'post',
      //   url: `${window.BASE_URL_USER}get-expiry-token`,
      //   withCredentials : true,
      //   headers: {'Content-Type': 'application/json' },
      //   data: JSON.stringify({'requested': requestExpiryToken}),
      // };
  
      // axios(config)
      // .then(function(res){
        if (userContext['search_params']){
          let url_token = '';
          if(url.includes('__PUBLIC_TOKEN__') &&  userContext['search_params']  ){
            url_token = url.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
          }
          window.open(url_token,'_blank','noreferrer');
        }
        else{
          // if 400 previours url
            console.log('not found');
            window.open(urlPdf,'_blank','noreferrer');
        }
      setRequestExpiryToken('');
    }
  },[requestExpiryToken]);

  const previewUrl = useMemo(
    () => {
      if (props.result.preview_url) {
        return props.result.preview_url.replace("__PUBLIC_TOKEN__", userContext.search_params);
      }
    }, [props.result.preview_url]
  );

  useEffect(() => {
    console.log('34props.keyToScroll',props.keyToScroll);
    console.log('34props.keyToScroll', props.annotation_id);
    console.log('34props.props.keyToScroll === props.annotation_id', props.keyToScroll === props.annotation_id);
    const listNode = AnnotationsRef.current;
    if(props.elementToScroll && props.keyToScroll && props.annotation_id &&props.keyToScroll === props.annotation_id ){
      console.log('41',props.elementToScroll);
      if(typeof props.elementToScroll=== "string"){
      console.log('43',props.elementToScroll);

        const listNode = AnnotationsRef.current;
        const annotationCardNode = document.querySelectorAll(props.elementToScroll);
        console.log('48',annotationCardNode);
        console.log('48',document.querySelectorAll(props.elementToScroll));
        let temp = props.elementToScroll.split('h2 span.');
        console.log('48',document.getElementById(temp));
        

        if(listNode){
        console.log('48',listNode);
        console.log('48',listNode.querySelectorAll('h2'));
        console.log('48',listNode.querySelectorAll('h2 > span'));
        console.log('48',listNode.querySelectorAll(props.elementToScroll));}
        if(listNode){
          listNode.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }
        if(annotationCardNode && annotationCardNode.length>0 ){
          console.log('48',props.elementToScroll);
          annotationCardNode[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
          // props.setElementToScroll(undefined);
          // props.setkeyToScroll(undefined);
        }
        else{
          console.log('53',annotationCardNode);
        }
      }
    }

  }, [props.keyToScroll]);

    useEffect(() => {
    console.log('72',document.querySelectorAll(props.elementToScroll));
  }, []);


  return (
    <Article className={(props.isOnSearchPage && 'card_chat_on_search_container')} >
      <div className={"card " + (!props.isSearchResult && 'card_chat ') + (props.isOnSearchPage && 'card_chat_on_search')} onClick={(e) => handleCardClick(e, props.result, props.i)}>
        <div className="card_top">
          {props.isSearchResult && cardExpanded && !isSmallScreen &&  (
            <span onClick={(e) => handleUnexpanded(e, props.result, props.i)}
              className={ `card_expandIcon false`}>
              <FaChevronUp />
            </span>
          )}
          {props.isSearchResult && !cardExpanded &&  !isSmallScreen&& (
            <span
              className={`card_expandIcon undefined`}>
              <FaChevronDown />
            </span>
          )}
          <div className="main-result-body">
            {!isSmallScreen && iframeAllowed && !("preview" in props.result) && (
              <div className={`pdf-preview-container`} onClick={(e)=>setShowPDFModalFlag(props.i)}>
                <Suspense fallback={<div></div>}>
                  {/* we porbably should have an error if no online_pdf_url */}
                  <PDFPreview data={(Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] : props.result.online_view_url} />
                </Suspense>
              </div>
            )}
            {!isSmallScreen && props.result.preview === 'image_portrait' && (
              <div className={`pdf-preview-container`} onClick={(e)=>setShowPDFModalFlag(props.i)}>
                <img className="pdf-preview" src={previewUrl} width="100" height="141" />
              </div>
            )}
            {!isSmallScreen && props.result.preview === 'image_landscape' && (
              <div className={`pdf-preview-container1`} onClick={(e)=>setShowPDFModalFlag(props.i)}>
                <img className="pdf-preview" src={previewUrl} width="141" height="100" />
              </div>
            )}

            <div className={`${isSmallScreen ? "card_header_mobile":"card_header"} ${(iframeAllowed || props.result.preview === 'image_portrait') && "card_header_left"} ${props.result.preview === 'image_landscape' && "card_header_left1"}`}>
              {isSmallScreen ? (
                <>
                {props.isSearchResult ? (
                  <H1 h1 regular className='mobile_title'>
                    {props.annotation_id&&( <H1 ref={AnnotationsRef} h1 regular dangerouslySetInnerHTML={{__html: props.annotation_id}}></H1>)}
                    <a
                      href={ (Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] :props.result.online_view_url}
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_self"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} 
                    />
                    {props.result.online_view_url && (
                      <div style={{marginBottom:"3px", lineHeight: "14px"}}>
                          <span className={isSmallScreen?"mobileDate":""}>
                            {/* we leave this url as display but maybe we should leave online_pdf_url  */}
                            {props.result.online_view_url && webOnlineViewUrl}
                          </span>
                      </div>
                    )}
                  </H1> 
                ) : ( // Mobile Assisted AI: yellow text + url
                  <div className='card_body_container'>
                    <div className='card_body expanded' aria-expanded={true}>
                      {props.result.modified_date && isSmallScreen ? (
                        <div className="search_card_table_wrapper_mobile"
                        dangerouslySetInnerHTML={{ __html: formattedDate(props.result.modified_date) + ' — ' + props.purifiedHtml }} />  
                      ) : (
                        <div className="search_card_table_wrapper_mobile"
                        dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} />  
                      )}    
                    </div>
                    {props.result.online_view_url && (
                      <div style={{marginBottom:"3px", lineHeight: "14px"}}>
                          <span className={isSmallScreen?"mobileDate":""}>
                            {/* we leave this url as display but maybe we should leave online_pdf_url  */}
                            {props.result.online_view_url && webOnlineViewUrl} 
                          </span>
                      </div>
                    )}
                  </div>
                )}
                </>            
              ) : (
                <>
                  {props.isSearchResult ? (
                    <div className={'title-div'} ref={AnnotationsRef} style={{ display: props.annotation_id && 'flex',gap: props.annotation_id &&'0.3rem'}}>
                    {props.annotation_id &&( <H2  h2 semibold dangerouslySetInnerHTML={{__html: props.annotation_id}}></H2>)}
                      <H2 h2 semibold>
                        <a
                          href={ (Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] : props.result.online_view_url}
                          onClick={(e) => props.handleLinkoutClick(e, props.result)}
                          target="_blank"
                          rel="noopener noreferrer"
                          dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} />
                      </H2> 
                    </div>
                  ) : (
                    <div className='card_body_container'>
                      <div
                        className={`card_body expanded`}
                        aria-expanded={true}
                      >
                        {props.isOnSearchPage ? (
                          <div className="search_card_table_wrapper" dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} />  
                        ) : (
                          <div className="search_card_table_wrapper">
                            <TypewriterAnimationChat string={props.purifiedHtml}/>  
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {!props.isSearchResult && (
              <>
                {/* <div className='score1'>
                  <span className='score'>Consensus score: {props.result.result_consensus_score}%</span>
                </div> */}
                <Hr style={{'marginTop': '5px', 'marginBottom': '5px'}}/>
              </>
            )}

            {!props.isSearchResult ? (
              <>
              {!isSmallScreen ? (
                <div className='title-div' >
                  {props.annotation_id&&( <H2 ref={AnnotationsRef} h2 semibold dangerouslySetInnerHTML={{__html: props.annotation_id}}></H2>)}
                  <H2 h2 semibold>
                    <a
                      href={ (Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] :  props.result.online_view_url} // cam we have no isOnlineViewUrlPdf 
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} />
                  </H2> 
                </div>
              ) : ( // Mobile Assisted AI: Dark blue headline
                <div className="card_header_mobile">
                  <H1 h1 regular className='mobile_title'>
                    {props.annotation_id&&( <H1 ref={AnnotationsRef} h1 regular dangerouslySetInnerHTML={{__html: props.annotation_id}}></H1>)}
                    <a
                      href={ (Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] : props.result.online_view_url}
                      onClick={(e) => props.handleLinkoutClick(e, props.result)}
                      target="_self"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} 
                    />
                  </H1> 
                </div>
              )}
              </>
            ) : (
              <div className='card_body_container'>
                <div
                  className={cardExpanded ? `card_body expanded` : `card_body undefined`}
                  aria-expanded={props.i === -1 ? true : false}
                >
                  {props.result.modified_date && isSmallScreen ? (
                    <div className={isSmallScreen? "search_card_table_wrapper_mobile":"search_card_table_wrapper"}
                    dangerouslySetInnerHTML={{ __html: formattedDate(props.result.modified_date) + ' — ' + props.purifiedHtml }} />  
                  ) : (
                    <div className={isSmallScreen? "search_card_table_wrapper_mobile":"search_card_table_wrapper"}
                    dangerouslySetInnerHTML={{ __html: props.purifiedHtml }} />  
                  )}    
                </div>
              </div>
            )}
          </div>
          <div className="card_footer">
            <div className="card_footer_1">
              <div>
                {props.result.doc_publisher && props.publisherSource &&(props.publisherSource["url"] ) &&
                  props.result.doc_publisher !== 'Web' && (
                    <PublisherTag publisher={props.publisher} isFolder={props.result.parent_folder}
                    isPrivate={props.result.from === "private"} url={props.publisherSource.url } 
                    encrypted_url={props.publisherSource.encrypted_url }
                    requestToken={props.result.requested_obj ? props.result.requested_obj : ""} 
                    inMobileResult ={ isSmallScreen} />
                  )
                }
                {!isSmallScreen && !props.isOnlineViewUrlPdf && (
                  <A
                    c2
                    semibold
                    textlink
                    className="card_footer_1--external"
                    onClick={(e) => props.handleLinkoutClick(e, props.result)}
                    href={ props.result.online_view_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {webOnlineViewUrl}
                  </A>
                )}
                {props.result.doc_publisher && props.isOnlineViewUrlPdf && props.isOnlineViewUrlPdf && !isSmallScreen && (
                  <A
                    c2
                    semibold
                    textlink
                    className="card_footer_1--external--pdf"
                    onClick={(e) => handleDownload(e, props.result)}
                    href={(Object.hasOwn(props.result, 'online_pdf_url') && typeof props.result['online_pdf_url']!=="undefined" ) ? props.result['online_pdf_url'] : props.result.online_view_url}
                    download
                  >
                    {props.isOnlineViewUrlPdf
                      ? webOnlineViewUrl
                      : ''}
                  </A>
                )}
              </div>
              {!isSmallScreen && props.result.modified_date && props.result.modified_date != "" && (
                <div className="card_footer_1--retrieved">
                  <Span c2 regular textplaceholder>
                    Last updated:{' '}
                    <Span c2 semibold textoffblack>
                      {isToday(new Date(props.result.modified_date)) ? "Today" : isYesterday(new Date(props.result.modified_date)) ? "Yesterday" : (new Date(props.result.modified_date)).toString().substring(4, 15)}
                    </Span>
                  </Span>
                </div>
              )}
            </div>
          </div>
        </div>

        {props.isSearchResult && (
          <div className="card_footer">
            <div className={cardExpanded && !isSmallScreen ? 
              "card_footer_2 card_footer_2_" + "expanded" 
              : cardExpanded ? "card_footer_2 card_footer_2_expanded":"card_footer_2 card_footer_2_undefined" }>
              {!isSmallScreen && (props.isLoggedin && !props.isMagicUser) && props.bookmarkedFetched && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container">
                    <CpdNotes isLoggedin={props.isLoggedin } bookmarks={props.hasBookmarks} updateBookmarks={props.updateBookmarks} setUpdateBookmarks={props.setUpdateBookmarks} 
                      isFavourite={props.isFavourite} updateFavourites={props.updateFavourites} favouriteID={props.favouriteID} isRemove={props.toBeRemove} 
                      search={searchContext} parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result} index={props.i} 
                      expandParent={callbackResultExpanded(props.result)}>
                    </CpdNotes>               
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={(e)=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                </>
              )}
              {!isSmallScreen && (!props.isLoggedin  || props.isMagicUser) && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container"> 
                    <CpdNotes isLoggedin={props.isLoggedin && !props.isMagicUser} bookmarks={props.hasBookmarks} search={searchContext}
                      parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result}
                      index={props.i} expandParent={callbackResultExpanded(props.result)}>
                    </CpdNotes>
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={(e)=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {typeof showModalFlag !== 'undefined' &&  showModalFlag === props.i && (
          <ModalSearchFeedback
            modalFeedbackShow={ showModalFlag === props.i }
            modalFeedbackClose={setShowModalFlag} 
            title=" Let us know how to improve medwise.ai "
            result = {props.result}
            i = {props.i} 
          />
        )}

        {typeof showPDFModalFlag !== 'undefined' &&  showPDFModalFlag === props.i &&(
          <Suspense fallback={<div></div>}>
            <ModalPDFPreview
              modalPDFShow={ showPDFModalFlag === props.i }
              modalPDFClose={setShowPDFModalFlag} 
              title={props.result.chunk_title}
              result = {props.result}
              i = {props.i} 
            />
          </Suspense>
        )}
      </div>
    </Article>  
    );
        

};

export default ResultCard;
