import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import DOMPurify from 'dompurify';
import {
  stripTags,
  isUrlPdf,
  countWords,
} from '../../functions/utils';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as ErrorImage } from '../../assets/svgs/error-image.svg';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../context';
import AcResult from './AcResult';
import {
  FaCheck,
} from 'react-icons/fa';
import ModalSearchFeedback from '../modal-search-feedback';
import { H2, P, Span } from '../theme/typography';
import Hr from '../hr/index.js';

import {
  Styles,
  SuggestedQuery,
  SuggestedQuestion,
  CouldNotFindContainer,
} from './styles';
import { Tag } from '../filters/filters-desktop-tags/styles';

import { product_name } from '../../functions/frontend_variables';
import axios from 'axios';
import {
  getUser,
  getDictUserSources,
  getAllSources,
} from '../../functions/manageSessions';
import AcUserResult from './AcUserResult';
import OverideCard from './override-card/index.js';
import ResultCard from './result-card';
import WebCard from './web-card/index.js';
import DrugNameButtons from '../drug-Name-buttons/index.js';
import DifferentSearchNotification from '../different-search-notification/index.js';
import SuggestedQuestionContainer from './suggested-question-container/index.js';
import GeneratedAnswer from './generated-answer/index.js';
import ExtractiveAnswer from './extractive-answer/index.js';

const ResultCards = () => {
  const {
    fetchedResults,
    feedbackContext,
    setFeedbackContext,
    searchContext,
    setSearchContext,
    setRequestTrackerContext,
    fetchedQuery,
    fetchedSuggestedQuestion,
    userContext,
    queries,
    setQueries,
    progress,
    setProgress,
    setResultsFirstExpanded,
    setLoadMore,
    assistedAiAutoexpandWordCount,
    confirmCouldNotFind,
    setConfirmCouldNotFind,
    suspectCouldNotFind,
    setSuspectCouldNotFind,
    setClearLocalWebResults,
    setShowAssistedAI,
    setLocalWebResults,
    llmAnswerUnavailable,
  } = useContext(AppContext);
  const controller = new AbortController();
  // const CancelToken = axios.CancelToken;
  // const source = CancelToken.source();
  const isSmallScreen =  useIsSmallScreen();
  const [acResults, setAcResults] = useState([]);
  const [purifyHtml, setPurifyHtml] = useState([]);
  const [acUserResults, setUserAcResults] = useState([]);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isMagicUser, setIsMagicUser] = useState(false);
  const [bookmarks, setBookmarks] = useState(undefined);
  const [bookmarksFetched, setBookmarksFetched] = useState(false);
  const [updateBookmarks, setUpdateBookmarks] = useState(false); // TODO Change to trigger bookmark update
  const [requestExpiryToken, setRequestExpiryToken] = useState('');
  const [hasOnlinePdfUrl, setHasOnlinePdfUrl] = useState(false);
  const [urlPdf, setUrlPdf] = useState('');
  const [favourites, setFavourites] = useState(undefined);
  const [url, setUrl] = useState('');
  const [hasNoSources, setHasNoSources] = useState(false);
  const [isOnlineViewUrlPdf, setIsOnlineViewUrlPdf] = useState([]);
  const [resultHasBookmark, setResultHasBookmark] = useState([]); // an array representing weather or the results is bookmarked
  const [resultHasFavourite, setResultHasFavourite] = useState([]); // an array representing weather or the results is favourite
  const [resultFavouriteID, setResultFavouriteID] = useState([]); // an array of favourite ids if the results has no favoiryt id = -1
  const [favouriteRemove, setFavouriteRemove] = useState([]); // an array of favourite to be remove
  const [allPublishersNames, setAllPublishersNames] = useState([]);
  const [publishersSources, setPublishersSources] = useState([]);
  const [onlineViewUrl, setOnlineViewUrl] = useState([]);
  const [dots, setDots] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const intervalRef = useRef(null);

  // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link
  const handleLinkoutClick = (e, result) => {
    e.stopPropagation();
    let body = {
      online_view_url: result.online_view_url,
      interaction_type: 'visit',
    };
    if (
      result.from &&
      result.from === 'private' &&
      !(result['requested_obj'] && result['requested_obj'] === 'override')
    ) {
      // if result is private , we check if the source needs an /get-expiry-token
      if (result.requested_obj) {
        e.preventDefault();
        setUrl(result.online_view_url);
        if (result.online_pdf_url) {
          setHasOnlinePdfUrl(true);
          setUrlPdf(result.online_pdf_url);
        } else {
          setHasOnlinePdfUrl(false);
        }
        setRequestExpiryToken(result.requested_obj);
      }
      // url comes form private but it is not a private src
      // so no need to request token
      else {
        if (result.online_pdf_url) {
          e.preventDefault();
          setHasOnlinePdfUrl(true);
          setUrlPdf(result.online_pdf_url);
          window.open(result.online_pdf_url,'_blank','noreferrer');
        } else {
          setHasOnlinePdfUrl(false);
        }
      }
    } 
    // TO DO move to useEffect
    // request should be in useEffect to allow for cancel at clean up
    if (searchContext.q) {
      body = {
        ...body,
        q: searchContext.q,
      };
    }
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };




  const handleFeedBackClick = (
    e,
    chunkId,
    tittle,
    online_view_url,
    source,
    score,
    rank
  ) => {
    let clickedVoteIcon = e.currentTarget;

    // If this button has already been clicked, don't send the vote again
    if (clickedVoteIcon.classList.contains('thumbs_icon_voted')) {
      return;
    }

    let upVoteIcon = document.getElementById('thumbs_up_chunk_' + chunkId);
    let downVoteIcon = document.getElementById('thumbs_down_chunk_' + chunkId);

    // Reset states on both
    upVoteIcon.classList.remove('thumbs_icon_voted');
    downVoteIcon.classList.remove('thumbs_icon_voted');
    upVoteIcon.classList.add('thumbs_icon_unvoted');
    downVoteIcon.classList.add('thumbs_icon_unvoted');

    // Add voted class to change color for it, to let user know their vote has been sent
    clickedVoteIcon.classList.remove('thumbs_icon_unvoted');
    clickedVoteIcon.classList.add('thumbs_icon_voted');
    let facets = searchContext.facets.map((x) => x.name);
    let body = {
      chunk_id: chunkId,
      chunk_title: tittle,
      online_view_url: online_view_url,
      'selected-filters': facets,
      doc_publisher: source,
      value: score,
      rank: rank,
      product: product_name,
      event: 'vote',
      q: searchContext.q,
    };

    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}add-user-logs`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };

    axios(config);
  };


  // input: a string representing a encrypted_url
  // output: a string with the name of the sources
  const get_publisher_name = (val) => {
    const default_sources_dict = getDictUserSources();
    if (
      default_sources_dict[val] &&
      default_sources_dict[val].name &&
      default_sources_dict[val].name.trim() !== ''
    ) {
      return default_sources_dict[val].name;
    }
    return val;
  };

  // input: a string representing a encrypted_url
  // output: a object
  const get_publisher_source = (encrypted_url) => {
    const default_sources_dict = getDictUserSources();
    let out = undefined;
    if (
      default_sources_dict[encrypted_url] &&
      default_sources_dict[encrypted_url].name &&
      default_sources_dict[encrypted_url].name.trim() !== ''
    ) {
      out = default_sources_dict[encrypted_url];
    }
    // else{
    //   const facets = searchContext.facets;
    //   // if we dont find anything we look for search Context the name
    //   if (searchContext['facets']){
    //     searchContext['facets'].forEach(facet => {
    //       if(facet && facet['name'] && facet['name'].toLowerCase() === encrypted_url.toLowerCase() ){
    //         out = facet;
    //       }
    //     });
    //   }
    // }
    return out;
  };

  const newQuerySubmit = useCallback((e, queryFromACClick) => {
    e.preventDefault();
    let query;
    if (queryFromACClick) {
      query = queries[0] + ' ' + stripTags(queryFromACClick);
    }
    // get selected filters
    // This state not only triggers the API call
    setSearchContext({
      ...searchContext,
      demo_q_clicked: false,
      'from-trending': false,
      q: query,
      covid_only: false,
    });

    setQueries([query]);

    closeAutoCompleteWrapper();
  });

  // This where the submission is.
  // Change of "q" parameter in setSearchContext triggers the API call in context if queryFromACClick .
  // queryFromACClick is passed from the AcResult.js. Therefore, 'clicked value' needs to be passed to this function to trigger the search.
  const querySubmit = useCallback((e, queryFromACClick) => {
    e.preventDefault();
    let query;
    if (queryFromACClick) {
      query = stripTags(queryFromACClick);
    }
    setSuspectCouldNotFind(false);
    setConfirmCouldNotFind(false);
    setLoadMore(undefined);
    setClearLocalWebResults(false);
    setShowAssistedAI(true);
    setLocalWebResults({ 0: [], 1: [], 2: [], 3: [], 4: [] });
    // get selected filters
    // This state not only triggers the API call
    setSearchContext({
      ...searchContext,
      demo_q_clicked: false,
      'from-trending': false,
      q: query,
      covid_only: false,
    });
    closeAutoCompleteWrapper();
  });
  // Close the autocomplete wrapper. Same as useOutsideClickDetector and escape button press
  const closeAutoCompleteWrapper = () => {
    setAcResults(undefined);
    setUserAcResults(undefined);
  };

  const getAllSourcesForSuggest = () => {
    let allSourcesObj = getAllSources();
    let allSources = '';
    if (allSourcesObj && allSourcesObj.length > 0) {
      allSourcesObj.forEach((element) => {
        if (element['encrypted_url']) {
          allSources = allSources + ',' + element['encrypted_url'];
        }
      });
      allSources = allSources.substring(1);
    } else {
      allSources = undefined;
    }
    return allSources;
  };

  const getUserSuggestions = (query) => {
    let allSources = getAllSourcesForSuggest();
    if (
      allSources &&
      typeof allSources !== 'undefined' &&
      query &&
      query !== ''
    ) {
      axios({
        method: 'post',
        url: `${window.BASE_URL_USER}user-suggestions`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          q: query.trim(),
          sources: allSources,
        }),
      })
        .then((res) => {
          if (res.data.suggestions && res.data.suggestions.length > 0) {
            let arrayRes = res.data.suggestions;
            let filterRes = arrayRes.filter(
              (x) => x['online_view_url'] === null && x['suggestion'] === null
            );
            let mapRes = filterRes.map((x) => x['suggestion']);
            if (mapRes.length > 0) {
              setUserAcResults(mapRes);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSuggestions = (query) => {
    let allSources = getAllSourcesForSuggest();
    if (allSources && typeof allSources !== 'undefined') {
      if (typeof query === 'string') {
        query = query.trim();
      }
      axios({
        method: 'post',
        url: `${window.BASE_URL_ASK}suggest`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          q: query,
          sources: allSources,
        }),
      })
        .then((res) => {
          setAcResults(res.data.suggestions);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getFooter = function () {
    setRequestTrackerContext((prev) => {
      return { ...prev, 'get-footer': true };
    });
    axios({
      method: 'post',
      url: `${window.BASE_URL_USER}get-footer`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        online_view_urls: onlineViewUrl,
      }),
    })
      .then((res) => {
        setRequestTrackerContext((prev) => {
          return { ...prev, 'get-footer': false };
        });
        console.log('res', res);
        console.log('res.data[favourites]',res.data['favourites']);
        console.log('res.data[cpd_entries]',res.data['cpd_entries']);

        setFavourites(res.data['favourites']); //TO DO if user changes this should change to false
        setBookmarks(res.data['cpd_entries']); ///TO DO if user changes this should change to false
        setBookmarksFetched(true); //TO DO if user changes this should change to false
      })
      .catch((error) => {
        setRequestTrackerContext((prev) => {
          return { ...prev, 'get-footer': false };
        });
        console.log('Error: ', error);
      });
  };

  const callbackResultExpanded = (result, i) => {
    return () => {
      result.expanded = 'expanded';
      result.noteExpanded = true;
    };
  };


  // returns all result that have a bookmark and
  // are assotiated to that result object
  // input: a result object
  // output: an array of result object associated with input
  const hasBookmark = (result) => {
    let out_bookmark = [];
    if (bookmarks && bookmarks.length > 0 && result) {
      bookmarks.forEach((bookmark) => {
        if (
          bookmark &&
          bookmark['online_view_url'] &&
          result['online_view_url'] &&
          bookmark['online_view_url'] === result['online_view_url']
        ) {
          out_bookmark.push(bookmark);
        }
      });
    }
    return out_bookmark;
  };

  const hasFavourite = (result) => {
    let found = false;

    if (favourites && favourites.length > 0 && result) {
      favourites.forEach((favourite) => {
        if (
          favourite &&
          favourite['online_view_url'] &&
          result['online_view_url'] &&
          favourite['online_view_url'] === result['online_view_url']
        ) {
          found = true;
        }
      });
    }
    return found;
  };

  // loops through all favorite & returns id of favorite
  // input : a result obj
  // out: a int
  // if no favorite -1  si return
  const getFavouriteID = (result) => {
    let id = -1;
    if (favourites && result) {
      favourites.forEach((favourite) => {
        if (
          favourite &&
          favourite['online_view_url'] &&
          result['online_view_url'] &&
          favourite['online_view_url'] === result['online_view_url']
        ) {
          id = favourite['id'];
        }
      });
    }
    return id;
  };

  // update arrays keeping track of favorite results
  const updateFavourites = (i, id) => {
    let temp_favourite = resultHasFavourite;
    let temp_id = resultFavouriteID;
    let favourite_remove = favouriteRemove;
    if (temp_favourite) {
      temp_favourite[i] = !temp_favourite[i];
      temp_id[i] = id;

      setResultHasFavourite(temp_favourite); // updated hasfavority
      setResultFavouriteID(temp_id); // update favourite
      if (temp_favourite[i] === false) {
        // if we are removing a favorite we need to updte the favouriteRemove array
        favourite_remove[i] = true;
        setFavouriteRemove(favourite_remove);
      } else {
        favourite_remove[i] = false;
        setFavouriteRemove(favourite_remove);
      }
    }
  };

  useEffect(() => {
    let user_info = getUser();
    if (user_info) {
      setIsLoggedin(true);
      if (user_info && user_info['org-user-type'] === 'magic') {
        setIsMagicUser(true);
      } else {
        setIsMagicUser(false);
      }
    } else {
      setIsLoggedin(false);
      setIsMagicUser(false);
    }
  }, [isLoggedin]);

  useEffect(() => {
    if (searchContext.facets && searchContext.facets.length <= 0) {
      setHasNoSources(true);
    } else {
      setHasNoSources(false);
    }
    getUserSuggestions(searchContext.q);
    getSuggestions(searchContext.q);
  }, [searchContext.q, fetchedResults]);


  useEffect(() => {
    if (fetchedResults && onlineViewUrl && onlineViewUrl.length > 0) {
      // let res = getFavourites(searchContext);
      if (!isLoggedin) {
        setBookmarks([]);
      } else {
        let res1 = getFooter();
      }
    }
  }, [onlineViewUrl, isLoggedin, updateBookmarks]);

  useEffect(() => {
    if (requestExpiryToken !== '' && url !== '') {
      if (userContext['search_params']) {
        let url_token = '';
        if (hasOnlinePdfUrl) {
          let s = urlPdf.replace(
            '__PUBLIC_TOKEN__',
            userContext['search_params']
          );
          if (s !== urlPdf) {
            url_token = s;
          }
        }
        window.open(url_token,'_blank','noreferrer');
      } else {
        // if 400 previous url
        console.log('not found ');
        window.open(urlPdf,'_blank','noreferrer');
      }
      setRequestExpiryToken('');
    }
  }, [requestExpiryToken]);


  // is UrlPDF should not be call at render but at the start
  useEffect(() => {
    //isUrlPdf(result.online_view_url)
    let newArrayPdf = [];
    let publishersNames = [];
    let favouritesIDArray = [];
    let onlineViewUrlArray = [];
    let allPublishersSources = [];
    let publishersUrl = [];
    if (fetchedResults) {
      fetchedResults.forEach((result) => {
        newArrayPdf.push(isUrlPdf(result.online_view_url)); // isUrlPdf() only works with online_view_url so no need to check online_pdf_url 
        favouritesIDArray.push(getFavouriteID(result));
        publishersUrl.push(result.doc_publisher);
        let publisher = get_publisher_source(result.doc_publisher);
        if (typeof publisher === 'undefined' && result.online_view_url) {
          allPublishersSources.push({ url: result.online_view_url });
        } else {
          allPublishersSources.push(publisher);
        }
        let tempName = result.doc_publisher;
        if (publisher !== undefined && publisher['name']) {
          tempName = publisher['name'];
        }
        publishersNames.push(tempName);
        onlineViewUrlArray.push(result.online_view_url);
      });
      setIsOnlineViewUrlPdf(newArrayPdf);
      setResultFavouriteID(favouritesIDArray);
      setFavouriteRemove([]);
      setAllPublishersNames(publishersNames);
      setPublishersSources(allPublishersSources);
      setOnlineViewUrl(onlineViewUrlArray);
    }
  }, [fetchedResults]);

  // is UrlPDF should not be call at render but at the start
  useEffect(() => {
    //isUrlPdf(result.online_view_url)
    let hasBookmarksArray = [];
    let hasFavouritesArray = [];
    if (fetchedResults) {
      fetchedResults.forEach((result) => {
        hasBookmarksArray.push(hasBookmark(result));
        hasFavouritesArray.push(hasFavourite(result));
      });
      setResultHasBookmark(hasBookmarksArray);
      setResultHasFavourite(hasFavouritesArray);
    }
  }, [bookmarks]);

  useEffect(() => {
    let hasFavouritesArray = [];
    let favouriteIDArray = [];
    let onlineViewUrlArray = [];
    if (fetchedResults) {
      fetchedResults.forEach((result) => {
        hasFavouritesArray.push(hasFavourite(result));
        favouriteIDArray.push(getFavouriteID(result));
        onlineViewUrlArray.push(result.online_view_url);
      });
      setResultHasFavourite(hasFavouritesArray);
      setResultFavouriteID(favouriteIDArray);
    }
  }, [favourites]);


  const goToAddSources = () => {
    if (isMagicUser || !isLoggedin) {
      window.open(window.BASE_URL_DASHBOARD + '/signup', '_self');
    } else {
      window.open(
        window.BASE_URL_DASHBOARD + 'dashboard/sources.html',
        '_self'
      );
    }
  };

  useEffect(() => {
    let currentPurifyHtml = [];
    if (fetchedResults) {
      DOMPurify.addHook('afterSanitizeElements', function (node) {
        // console.log(node);
        if ('target' in node) {
          node.setAttribute('rel', 'noopener noreferrer');
          node.setAttribute('target', '_blank');
        }
        if (node.nodeName && node.nodeName === 'P') {
          // node.textContent = node.textContent.toUpperCase();
          if (
            node.textContent.trim().split(' ').length <= 1 &&
            node.parentNode
          ) {
            node.insertAdjacentHTML('afterend', node.textContent);
            node.remove();
          }
        }
      });
      fetchedResults.forEach((result) => {
        if (result['text']) {
          let out = DOMPurify.sanitize(result['text'], {
            FORBID_TAGS: ['img'],
            ADD_ATTR: ['target'], 
          });
          currentPurifyHtml.push(out);
        } else {
          currentPurifyHtml.push('');
        }
      });
    }
    setPurifyHtml(currentPurifyHtml);
  }, [fetchedResults]);

  useEffect(() => {
    if (fetchedSuggestedQuestion) {
      setProgress(0);
      intervalRef.current = setInterval(increaseProgress, 250);
      return () => clearInterval(intervalRef.current);
    }
  }, [fetchedSuggestedQuestion]);

  function increaseProgress() {
    setProgress((prevState) => prevState + 95 / 60);
  }

  useEffect(() => {
    if (progress >= 95 && progress < 100) {
      // setProgress( prev => 95);
      clearInterval(intervalRef.current);
    } else if (progress >= 100) {
      clearInterval(intervalRef.current);
      setProgress(100);
    }
  }, [progress]);


  useEffect(() => {
    // if (loadMore == null || loadMore) {
    setDots(0);
    const interval = setInterval(in_progress, 300);
    return () => clearInterval(interval);
    // };
  }, []);


  function in_progress() {
    if (dots < 3) {
      setDots((prevState) => {
        return prevState + 1;
      });
    } else {
      setDots(0);
    }
  }

  function couldNotFindHandler() {
    setConfirmCouldNotFind(true);
    setOpenModal(true); // now the modal takes care of getting the the feedbackId
  }

  function openHeyMiah() {
    window.open('https://heymiah.com/');
  }

  const feebackSuccessHandler = () => {
    setFeedbackSuccess(true);
  };

  function openModalHandler() {
    if (confirmCouldNotFind) {
      setOpenModal(true);
    }
  }

  // this is now done inside the modal
  const callReportIssue = (body) => {
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}report-issue`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
      signal: controller.signal,
      // cancelToken: source.token,
    };
    setRequestTrackerContext((prev) => {
      return { ...prev, 'report-issue': true };
    });
    axios(config)
      .then((res) => {
        setRequestTrackerContext((prev) => {
          return { ...prev, 'report-issue': false };
        });
      })
      .catch((error) => {
        setRequestTrackerContext((prev) => {
          return { ...prev, 'report-issue': false };
        });
        console.log('error: ', error);
      });
  };

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollTop >= 1400) {
      setSuspectCouldNotFind(true);
    }
  }, [scrollTop]);

  return (
    <>
      {/* component would not render if unless we get typeof replaced_query !== undefined */}
      <DifferentSearchNotification></DifferentSearchNotification>
      {/* component would not render if no drug results */}
      <DrugNameButtons></DrugNameButtons>

      {/* TRY HEYMIAH */}
      {/* {suspectCouldNotFind && (
        <div
          style={{
            background: 'rgba(229, 207, 80, 0.16)',
          }}
        >
          <CouldNotFindContainer>
            <SuggestedQuestion
              p1
              regular
              textoffblack
              className="title"
              onClick={openHeyMiah}
              style={{
                cursor: progress < 100 ? 'default' : 'pointer',
                marginBottom: isSmallScreen ? '8px' : '0.75rem',
              }}
            >
              <P
                className="suggestedQuery p-left"
                p1
                regular
                textoffblack
                style={{
                  lineHeight: '43px',
                  color: '#d39800',
                }}
              >
                {
                  <span>
                    Try our new AI assistant:{' '}
                    <span style={{ fontWeight: '600', fontStyle: 'italic' }}>
                      heyMiah.com
                    </span>
                  </span>
                }
              </P>
            </SuggestedQuestion>
          </CouldNotFindContainer>
        </div>
      )} */}

      {/* COULD NOT FIND */}
      {suspectCouldNotFind && (
        <div
          style={{
            background:
              confirmCouldNotFind && feedbackSuccess
                ? 'rgba(162, 208, 176, 0.16)'
                : 'rgba(223, 113, 113, 0.16)',
          }}
        >
          <CouldNotFindContainer>
            <SuggestedQuestion
              p1
              regular
              textoffblack
              className="title"
              onClick={couldNotFindHandler}
              style={{
                cursor: progress < 100 ? 'default' : 'pointer',
                marginBottom: isSmallScreen ? '8px' : '0.75rem',
              }}
            >
              <P
                className="suggestedQuery p-left"
                p1
                regular
                textoffblack
                style={{
                  lineHeight: '43px',
                  color:
                    confirmCouldNotFind && feedbackSuccess ? 'green' : 'red',
                }}
                // onClick={openModalHandler}
              >
                {confirmCouldNotFind && feedbackSuccess ? (
                  <FaCheck color="green" style={{ marginRight: '4px' }} />
                ) : null}
                {confirmCouldNotFind && feedbackSuccess
                  ? 'Thank you, we will let you know once this is solved.'
                  : 'Could not find what you were looking for? Click here and we will solve it.'}
              </P>
            </SuggestedQuestion>
          </CouldNotFindContainer>
        </div>
      )}

      <ModalSearchFeedback
        modalFeedbackShow={openModal}
        modalFeedbackClose={setOpenModal}
        result={false}
        resultNotFound={true}
        // if we want the request to get feedbackid to take place inside the modal
        // feedbackId has to = undefined
        // and requireId  has to = true
        feedbackId={undefined}
        // requiredId={true} // this props
        fromFeedbackBar={true}
        successFunction={feebackSuccessHandler}
        title="Let us know how to improve medwise.ai"
      />
      {/*  replacedQuery can not be present if fetchedQuery exist*/}
      {typeof fetchedQuery !== 'undefined' &&
        typeof fetchedQuery !== 'null' &&
        fetchedQuery.text &&
        typeof fetchedQuery.text === 'string' &&
        fetchedQuery.html &&
        typeof fetchedQuery.html === 'string' && (
          <SuggestedQuery p1 regular textoffblack className="title">
            <div className="spelling-search" aria-label="Search">
              <P p1 regular textoffblack semibold className="spelling-text">
                Did you mean:
              </P>
              <P
                className="suggestedQuery"
                p1
                regular
                textoffblack
                onClick={(e) => querySubmit(e, fetchedQuery.text)}
                dangerouslySetInnerHTML={{ __html: fetchedQuery.html }}
              ></P>
            </div>
          </SuggestedQuery>
        )}

      {/* {(fetchedAdvancedSuggestions != undefined && fetchedAdvancedSuggestions.length > 0) && (
        <SuggestedQuery  p1 regular textoffblack className="title">
          <div className="spelling-search" aria-label="Search">
            <P p1 regular textoffblack semibold  className='spelling-text'>Suggestions:</P>
            {fetchedAdvancedSuggestions && fetchedAdvancedSuggestions.map((qSuggested,i) =>
              <>
                {i !== (fetchedAdvancedSuggestions.length-1) ?
                  <P
                  className='advancedSuggestion'
                  p1
                  regular
                  textoffblack
                  onClick={(e) => newQuerySubmit(e, qSuggested)}
                  dangerouslySetInnerHTML={{ __html: '+'+qSuggested+',' }}
                  >
                  </P> : 
                  <P
                  className='advancedSuggestion'
                  p1
                  regular
                  textoffblack
                  onClick={(e) => newQuerySubmit(e, qSuggested)}
                  dangerouslySetInnerHTML={{ __html: '+'+qSuggested }}
                  >
                  </P> 
                }
              </>
            )}
          </div>
        </SuggestedQuery>
      )} */}
      {/*  replacedQuery can not be present if fetchedQuery exist*/}
      {typeof fetchedQuery !== 'undefined' &&
        typeof fetchedQuery !== 'null' &&
        typeof fetchedQuery === 'object' &&
        fetchedQuery.length > 0 && (
          <SuggestedQuery p1 regular textoffblack className="title">
            <div className="spelling-search" aria-label="Search">
              <P p1 regular textoffblack semibold className="spelling-text">
                Related searches:
              </P>
              {fetchedQuery &&
                fetchedQuery.map((qSuggested, i) => (
                  <>
                    {i !== fetchedQuery.length - 1 ? (
                      <P
                        className="suggestedQuery"
                        p1
                        regular
                        textoffblack
                        onClick={(e) => querySubmit(e, qSuggested.text)}
                        dangerouslySetInnerHTML={{
                          __html: qSuggested.html + ',',
                        }}
                      ></P>
                    ) : (
                      <P
                        className="suggestedQuery"
                        p1
                        regular
                        textoffblack
                        onClick={(e) => querySubmit(e, qSuggested.text)}
                        dangerouslySetInnerHTML={{ __html: qSuggested.html }}
                      ></P>
                    )}
                  </>
                ))}
            </div>
          </SuggestedQuery>
        )}

      {/* SUGGESTED QUESTION */}
      {/* <SuggestedQuestionContainer></SuggestedQuestionContainer> */}
      {/* Generated Anwser from Miah */}
      {userContext && Object.hasOwn(userContext,'ai_answer_type') && !llmAnswerUnavailable &&  (userContext['ai_answer_type'] === "extractive"  || userContext['ai_answer_type'] === "generative") ? (
        <>
          {userContext['ai_answer_type'] === "generative" &&(
          <GeneratedAnswer references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
            isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
            resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
            resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
            isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
            callbackResultExpanded={callbackResultExpanded}></GeneratedAnswer>
            ) }
          {userContext['ai_answer_type'] === "extractive" && !llmAnswerUnavailable && (
            <ExtractiveAnswer references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
              isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
              resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
              callbackResultExpanded={callbackResultExpanded}></ExtractiveAnswer>
              ) }
            <Container>
                  <div style={{ padding: "8px", display: 'flex', justifyContent:'center', alignItems:'center' }} >
                    <Hr style={{width:'30%'}}/>
                  </div>
          </Container>
        </>
        ):(
          <SuggestedQuestionContainer></SuggestedQuestionContainer>
        )}
      {/* {!isSmallScreen ? (<ReactTooltip place="bottom" effect="solid" offset={{bottom: 7}} backgroundColor="#212452" textColor="#FFF"/>) : '' } */}
      {fetchedResults &&
        fetchedResults
          .filter(
            (res) =>
              res.result_type !== 'drug' && res.result_type !== 'drug_children'
          )
          .map((result, i) => {
            return result['from'] &&
              result['requested_obj'] &&
              result['from'] === 'private' &&
              result['requested_obj'] === 'override' ? (
              <OverideCard
                key={
                  'override_' +
                  i +
                  '_' +
                  allPublishersNames[i] +
                  allPublishersNames[i]
                }
                i={i}
                isSearchResult={true}
                result={result}
                publisher={allPublishersNames[i]}
                publisherSource={publishersSources[i]}
                bookmarkedFetched={bookmarksFetched}
                hasBookmarks={resultHasBookmark[i]}
                updateBookmarks={updateBookmarks}
                setUpdateBookmarks={setUpdateBookmarks}
                isFavourite={resultHasFavourite[i]}
                toBeRemove={favouriteRemove[i]}
                favouriteID={resultFavouriteID[i]}
                isLoggedin={isLoggedin}
                isMagicUser={isMagicUser}
                updateFavourites={updateFavourites}
                callbackResultExpanded={callbackResultExpanded}
              ></OverideCard>
            ) : result['from'] &&
              !result['requested_obj'] &&
              !result['online_pdf_url'] &&
              result['from'] === 'reranker' &&
              result['doc_publisher'] === 'Web' ? (
              <WebCard
                key={'web_' + i + allPublishersNames[i]}
                i={i}
                isSearchResult={true}
                result={result}
                isOnlineViewUrlPdf={isOnlineViewUrlPdf[i]}
                publisher={allPublishersNames[i]}
                publisherSource={publishersSources[i]}
                purifiedHtml={purifyHtml[i]}
                bookmarkedFetched={bookmarksFetched}
                hasBookmarks={resultHasBookmark[i]}
                updateBookmarks={updateBookmarks}
                setUpdateBookmarks={setUpdateBookmarks}
                isFavourite={resultHasFavourite[i]}
                toBeRemove={favouriteRemove[i]}
                favouriteID={resultFavouriteID[i]}
                isLoggedin={isLoggedin}
                isMagicUser={isMagicUser}
                updateFavourites={updateFavourites}
                callbackResultExpanded={callbackResultExpanded}
                handleLinkoutClick={handleLinkoutClick}
              ></WebCard>
            ) : (
              <ResultCard
                key={'result_' + i + allPublishersNames[i]}
                i={i}
                isSearchResult={true}
                result={result}
                isOnlineViewUrlPdf={isOnlineViewUrlPdf[i]}
                publisher={allPublishersNames[i]}
                publisherSource={publishersSources[i]}
                purifiedHtml={purifyHtml[i]}
                bookmarkedFetched={bookmarksFetched}
                hasBookmarks={resultHasBookmark[i]}
                updateBookmarks={updateBookmarks}
                setUpdateBookmarks={setUpdateBookmarks}
                isFavourite={resultHasFavourite[i]}
                toBeRemove={favouriteRemove[i]}
                favouriteID={resultFavouriteID[i]}
                isLoggedin={isLoggedin}
                isMagicUser={isMagicUser}
                updateFavourites={updateFavourites}
                callbackResultExpanded={callbackResultExpanded}
                handleLinkoutClick={handleLinkoutClick}
              ></ResultCard>
            );
          })}
      {hasNoSources ? (
        <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  Please select a source or add a source below (signup required)
                </H2>
                <P p1 regular textmainbody>
                  <Tag key={-2} className="coloured" onClick={goToAddSources}>
                    <Span bold style={{ fontSize: '16px' }}>
                      {' '}
                      Add a source
                    </Span>
                  </Tag>
                </P>
                <P p1 regular textmainbody className="error_message"></P>
              </Col>
            </Row>
          </Container>
        </Styles>
      ) : (
        ''
      )}
      {fetchedResults &&
      fetchedResults.filter(
        (res) =>
          res.result_type !== 'drug' && res.result_type !== 'drug_children'
      ).length === 0 &&
      // && fetchedResults.length===0)
      !hasNoSources &&
      ((acResults && acResults.length > 0) ||
        (acUserResults && acUserResults.length > 0)) ? (
        <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  No results found for:
                  <P bold> {searchContext.q} </P>
                </H2>
                <P p1 regular textmainbody>
                  Try selecting different sources, or take a look at one of the
                  following queries:
                  <br />
                </P>
                <div className="ac">
                  <ul className="ac_list">
                    {acUserResults && (
                      <AcUserResult
                        list={acUserResults}
                        querySubmit={querySubmit}
                      />
                    )}
                    {acResults && (
                      <AcResult list={acResults} querySubmit={querySubmit} />
                    )}
                  </ul>
                </div>
                <P p1 regular textmainbody className="error_message"></P>
              </Col>
            </Row>
          </Container>
        </Styles>
      ) : (
        ''
      )}
      {fetchedResults &&
      fetchedResults.filter(
        (res) =>
          res.result_type !== 'drug' && res.result_type !== 'drug_children'
      ).length === 0 &&
      !hasNoSources &&
      ((acResults && acResults.length <= 0) || !acResults) &&
      (!acUserResults || (acUserResults && acUserResults.length <= 0)) ? (
        <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  No results found for:
                  <P bold> {searchContext.q} </P>
                </H2>
                <P p1 regular textmainbody>
                  Sorry, we don't seem to have an answer for this yet - we have
                  logged it and will investigate! In the meantime, try adjusting
                  your search query or selecting other sources.
                  <br />
                </P>
                <P p1 regular textmainbody className="error_message"></P>
              </Col>
            </Row>
          </Container>
        </Styles>
      ) : (
        ''
      )}

    </>
  );
};

export default ResultCards;
